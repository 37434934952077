
import React, { Component } from 'react';
import { GENERAL } from '../../assets/images/images';
import Helper from '../../core/helper/helper';
import LocalData from '../../core/localData';
import Emitter from '../../core/services';
import Labels from '../../variables/labels';

/**
 * Props definition
 * @typedef {Object} Props
 * @property {PropsData} data
 * @property {() => void} goToDetails 
 * @property {() => void} onRead 
 * @property {() => void} onReadCheck 
 */

/**
 * PropsData definition
 * @typedef {Object} PropsData
 * @property {'message' | 'check' | 'action' | 'internal' } type  // type of noti (message, check, action, internal notes)
 * @property {string} source  // represents the source of the view (details | BV)
 * @property {boolean} unRead // if notification wasn't read
 * @property {boolean} noFocus // to disable focus
 * @property {boolean} isCompact // if is for a compact card
 */

/**
 * StateData definition
 * @typedef {Object} StateData
 * @property {boolean} notiOpened
 * @property {boolean} iconClicked
 * @property {boolean} unRead // if notification wasn't read
 */

class ShowNotificationButton extends Component {
  constructor(/** @type {Props} */ props) {
    super(props);
    /** @type {Props} */
    const { data: {noFocus, unRead}} = this.props;
    /** @type {StateData} */
    this.state = {
      notiOpened: unRead && !noFocus,
      iconClicked: false,
      unRead
    }
  }

  /** @param { Props } prevProps */
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      /** @type {Props} */
      const { data: { unRead }} = this.props;
      this.setState({unRead});
    }
  }

  componentDidMount() {
    /** @type {Props} */
    const { data: { type, noFocus, source }} = this.props;

    /** @type {StateData} */
    const { unRead } = this.state;

    this.getSectionPositions();
    
    if (type === 'message') {
      if (LocalData.messageIconClicked) {
        this.focusOnObservationsBox();
        if (!Helper.isLiteVersion() && unRead) {
          this.props.onRead();
        }
      } else if (unRead && source==='details') {
        setTimeout(() => {
          this.focusOnObservationsBox();
          if (!Helper.isLiteVersion() && unRead) {
            this.props.onRead();
            this.setState({notiOpened: false});
            this.setState({unRead: false});
          }
        }, 1500); // 1500 milliseconds = 1.5 seconds        
      }
    }

    if (LocalData.checkIconClicked && type === 'check') {
      if (!Helper.isLiteVersion() && unRead) {
        this.props.onReadCheck();
      }
    }
    
    if (LocalData.approvalIconClicked && type === 'action') {
      this.focusOnApprovalBox(!noFocus);
    }

    if (LocalData.internalIconClicked && type === 'internal') {
      this.focusOnInternalNotesBox();
    }
  }

  focusOnObservationsBox = () => {
    if (!this.sectionElement) {
      return;
    }

    const elementBoundary = this.elementRect?.bottom + 80;
    const elementBoundaryMobile = this.elementRectMobile?.bottom + 80;

    this.scrollByOffset(elementBoundary, elementBoundaryMobile);
    setTimeout(() => {
      Emitter.emit('OBSERVATIONS_SHINING', true);
      LocalData.messageIconClicked = false;
    }, 500);
  }

  focusOnInternalNotesBox = () => {
    if (!this.sectionElement) {
      return;
    }

    const elementBoundary = this.internalContainer?.bottom + 80;
    const elementBoundaryMobile = this.internalContainerMobile?.bottom + 80;

    this.scrollByOffset(elementBoundary, elementBoundaryMobile);
    setTimeout(() => {
      Emitter.emit('INTERNAL_NOTES_SHINING', true);
      LocalData.internalIconClicked = false;
    }, 500);
  }

  focusOnApprovalBox = (focus) => {
    if (!focus || !this.sectionElement) {
      return;
    }

    const elementBoundary = this.elementRect?.top + 224;
    const elementBoundaryMobile = this.elementRectMobile?.top + 140;

    this.scrollByOffset(elementBoundary, elementBoundaryMobile);
    setTimeout(() => {
      Emitter.emit('APPROVAL_SHINING', true);
      LocalData.approvalIconClicked = false;
    }, 500);
  }

  toggleOpenIcon = (event, clicked) => {
    const { iconClicked, notiOpened } = this.state;
    if (event) {
      event.stopPropagation();
    }
    if (notiOpened && clicked && iconClicked) {
      this.setState({notiOpened: false});
    }
    if (!notiOpened) {
      this.setState({notiOpened: true});
    }
    
    if (notiOpened && !clicked && !iconClicked) {
      this.setState({notiOpened: false});
    }
    if (clicked) {
      this.setState({iconClicked: !iconClicked});
    }
  }

  onClickedLink = () => {
    /** @type {Props} */
    const { data: { type, source, noFocus }} = this.props;

    /** @type {StateData} */
    const { unRead } = this.state;
    if (!source) {
      if (type === 'message') {
        LocalData.messageIconClicked = true;
      }
      if (type === 'action') {
        LocalData.approvalIconClicked = true;
      }
      if (type === 'check') {
        LocalData.checkIconClicked = true;
      }      
      if (type === 'internal') {
        LocalData.internalIconClicked = true;
      }
      this.props.goToDetails();
      return;
    }
    switch (type) {
      case 'message':
        this.focusOnObservationsBox();
        if (!Helper.isLiteVersion() && unRead) {
          this.props.onRead();
          this.setState({notiOpened: false});
          this.setState({unRead: false});
        }
        break;
      case 'check':     
        if (!Helper.isLiteVersion() && unRead) {
          this.props.onReadCheck();
          this.setState({notiOpened: false});
          this.setState({unRead: false});
        }
        break;        
      case 'action':
        this.focusOnApprovalBox(!noFocus);
        this.setState({notiOpened: false});
        break;
      case 'internal':
        this.focusOnInternalNotesBox();
        if (!Helper.isLiteVersion()) {
          this.setState({notiOpened: false});
        }
        break;
      default:
        this.focusOnApprovalBox(false);
        break;
    }
  }
  
  renderInfotext = (isCompact) => {
     /** @type {Props} */
    const { data: { type, unRead }} = this.props;
    const { notiOpened } = this.state;
    if (!notiOpened || !isCompact) { return <></>; }

    const renderText = () => {
      const styleClass = (type === 'action' || type === 'message' || type === 'internal') && unRead ? 'link-container' : 'regular';
      const toasterTitle = Helper.getToastTitle(unRead, type);
      return (
        <React.Fragment>
          <div>{toasterTitle}</div>
          <div className={styleClass}>
            {Labels.notifications.description[type]}
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className={`notification-info-text d-flex align-items-center justify-content-center ${notiOpened ? 'notiOpened' : ''}`}
        onClick={(e) => this.toggleOpenIcon(e, true)}
      >
        <div className={`text-box ${type} d-flex align-items-center justify-content-center px-2`}
          onClick={() => (this.onClickedLink())}>
          { renderText() }
        </div>
      </div>
    );
  }

  getSectionPositions() {
    this.sectionElement = document.getElementById('main-details-container');
    this.sectionTop = this.sectionElement?.getBoundingClientRect()?.top;
    this.sectionBottom = this.sectionElement?.getBoundingClientRect()?.bottom;

    this.bodyTop = window.document.documentElement.getBoundingClientRect()?.top;
    this.bodyBottom = window.document.documentElement.getBoundingClientRect()?.bottom;

    this.elementRect = document.getElementById('service-tasks-list')?.getBoundingClientRect();
    this.elementRectMobile = document.getElementById('service-tasks-list-mobile')?.getBoundingClientRect();

    this.internalContainer =  document.getElementById('internal-notes-container')?.getBoundingClientRect();
    this.internalContainerMobile =  document.getElementById('internal-notes-container-mobile')?.getBoundingClientRect();
  }

  scrollByOffset(ePos, ePosMobile) {
    const offset = ePos - this.sectionTop - this.sectionBottom;
    const offsetMobile = ePosMobile - this.bodyBottom + 40;

    if (offset > 0) {
      this.sectionElement.scrollTo({ top: offset, behavior: 'smooth' });
    }
    if (offsetMobile > 0) {
      window.document.documentElement.scrollTo({ top: offsetMobile, behavior: 'smooth' });
    }
  }

  render() {
     /** @type {Props} */
    const { data: { type, source, isCompact }} = this.props;
    return (
      <div className={`notification-service-container ${type || ''} ${source || ''} d-flex align-items-center justify-content-center`}>
        {this.renderInfotext(!isCompact)}
        <div className={`notification-icon-box d-flex align-items-center justify-content-center`}
          onClick={(e) => this.toggleOpenIcon(e, true)}
        >
          <img src={GENERAL[type]} alt="" />
        </div>
        {this.renderInfotext(isCompact)}
      </div>
    );
  }
}
export default ShowNotificationButton;
