import React from 'react';
import 'components/card/service/serviceCard.style.scss';
import Helper from '../../../core/helper/helper';
import CardItemInformation from '../equipment/CardItemInformation';
import CardCorner from './cardCorner';
import { GENERAL } from '../../../assets/images/images';
import CardServiceState from './CardServiceState';
import FeedbackBoxCard from '../../feedback/feedbackBoxCard';
import Button from '../../button/button.jsx';
import Modal, { Information } from '../../modal/modal';
import Labels from '../../../variables/labels';
import ServiceNotificationWidgets from './serviceNotificationWidgets';
import Cookies from 'universal-cookie';
// import { toast } from 'react-toastify';

class ServiceDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    const {
      isLiteVersion,
      data: { id, item, phase, state, dateStart, old_date, dateEnd, old_time, time, 
        feedback, schedule, token, token_cal, checkoutInfo, showUnreadCheck,
        showUnreadMessage, hasMessage, allowApprove, showInternalNotes, isAppointment, is_cancelled, is_history, missedAppointment}
    } = this.props;
    this.refInput = React.createRef();
    this.state = {
      id: id,
      item: Helper.initialItemState(item, isLiteVersion, isAppointment),
      phase: phase,
      state: state,
      dateStartState: Helper.printDate(dateStart),
      dateEndState: Helper.printDate(dateEnd),
      dateStart,
      old_date,
      dateEnd,
      old_time,
      time,
      feedback: feedback,
      feedbackBD: feedback,
      schedule: schedule,
      stateWarning: state.bars === 5 && !feedback,
      stateAction: state.bars === 2.5, // aguarda aprovação
      stateCheck: state.bars === 4, // aguarda cliente
      showUnreadCheck,
      stateMessage: !!hasMessage, // tem mensagem de prestador
      showUnreadMessage,
      checkoutInfo,
      token,
      token_cal: token_cal,
      allowApprove: allowApprove,
      showInternalNotes,
      isAppointment: isAppointment,
      is_cancelled: is_cancelled,
      is_history,
      missedAppointment
    }
  }

  componentDidMount(){
    let searchParams = new URLSearchParams(this.props.location?.search);
    if (searchParams.has('feedback')) {
      searchParams.delete('feedback');
      this.props.history.push(`${this.props.location.pathname}?${searchParams.toString()}`);
      if (this.props.data?.state.bars===5 && !this.props.data.feedback && !this.props.isLiteVersion) {
        try{this.toggleShowFeedback();}
        catch{
          console.error('');
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const {
        data: { state, dateStart, old_date, dateEnd, old_time, time, feedback, schedule, checkoutInfo, showUnreadCheck, token_cal,
          showUnreadMessage, hasMessage, allowApprove, showInternalNotes, is_history, is_cancelled, missedAppointment }
      } = this.props;
      this.setState({
        state: { bars: state?.bars, name: state?.name },
        dateStartState: Helper.printDate(dateStart),
        dateEndState: Helper.printDate(dateEnd),
        dateStart,
        old_date,
        dateEnd,
        old_time,
        time,
        feedback: feedback,
        feedbackBD: feedback,
        schedule: schedule,
        stateWarning: state.bars === 5 && !feedback,
        stateAction: state.bars === 2.5, // aguarda aprovação
        stateCheck: state.bars === 4, // aguarda cliente
        showUnreadCheck,
        stateMessage: !!hasMessage, // tem mensagem de prestador
        showUnreadMessage,
        checkoutInfo,
        allowApprove: allowApprove,
        showInternalNotes,
        token_cal: token_cal,
        is_cancelled: is_cancelled,
        is_history,
        missedAppointment
      });
    }
  }

  handlePressEvent = () => {
    this.props.onClick(this.state.id);
  }

  validateSubmition = () => {
    const { feedback } = this.state;
    this.props.callback(feedback);
    this.toggleShowFeedback();
  }

  renderCardInformation = () => {
    const { id } = this.props;
    const { showFeedback, feedback } = this.state;
    if (showFeedback) {
      return(
        <FeedbackBoxCard data={{ valueRating: feedback?.classification, observation: feedback?.observation, id }} type="medium"
          callback={(f, isSub = false) => this.updateFeedbackState(f, isSub)}/>
      );
    }
    return (
      <React.Fragment>
        {this.renderItemInformation()}
        {this.renderCardServiceState()}
      </React.Fragment>
    );
  }

  updateFeedbackState = (feedback, isSubmition) => {
    this.setState({feedback})
    if (!isSubmition) {
      return;
    }

    this.validateSubmition();
  }

  renderItemInformation = () => {
    const { item: { title, subtitle } } = this.state;
    return <CardItemInformation data={{ title, subtitle }} styleClass="mt-3 mt-md-0 text-start details"/>
  }

  renderCardServiceState = () => {
    const { data: { schedule }} = this.props;
    const { state, dateStart, old_date, dateEnd, old_time, time, dateStartState, dateEndState, checkoutInfo, isAppointment, is_cancelled, is_history, missedAppointment } = this.state;
    return (
      <CardServiceState
        data={{ dateStart, old_date, schedule, dateEnd, old_time, time, state, dateStartState, dateEndState, checkoutInfo, isAppointment, is_cancelled, is_history, missedAppointment}}
        isSmall={false} styleClass='mt-2 mt-md-4 w-auto details'
      />
    );
  }

  renderCardCorner = (bars, feedback) => {
    if (this.state.showFeedback) {
      return;
    }
    return <CardCorner data={{bars, feedback}} styleClass="details"/>
  }

  renderLocationContainer = () => {
    const { data: { establishment } } = this.props;
    const { feedback, showFeedback, state: { bars } } = this.state;

    if (showFeedback) {
      return;
    }

    if (bars === 5) {
      const buttonLabel = feedback ? 'Ver Pontuação' : 'Enviar Feedback';
      return (
        <div className="d-flex justify-content-between">
          <div className="card-service-location" title={'Localização'}>
            <img src={GENERAL.location} alt="" />
            <div className="ms-2 card-service-location-text">
              {Helper.isDemo(establishment?.name) ? "Empresa Demo" : establishment?.name}
            </div>
          </div>
          <div className="cta-show-feedback" role={'button'}
            onClick={this.toggleShowFeedback}>
            <img src={GENERAL.starFillOrange} alt="" />
            <span className='ms-1 cta-text'>{buttonLabel}</span> 
          </div>
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-center justify-content-sm-start">
        <div className="card-service-location" title={'Localização'}>
          <img src={GENERAL.location} alt="" />
          <div className="ms-2 card-service-location-text">
            {Helper.isDemo(establishment?.name) ? "Empresa Demo" : establishment?.name}
          </div>
        </div>
      </div>
    );
  }

  onClickShare = () => {
    const { token, token_cal, isAppointment } = this.state;
    const shareLink = isAppointment? `https://${window.location.host}${process.env.PUBLIC_URL}/services-lite?token_cal=${token_cal}&s=C&t=0` : `https://${window.location.host}${process.env.PUBLIC_URL}/services-lite?token=${token}&s=C&t=0`;
    const shareData = {
      title: Labels.shareService.title,
      text: Labels.shareService.text,
      url: shareLink
    };
    this.openShareNavigator(shareData);
  };

  openShareNavigator(shareData) {
    const title = shareData.title;
    const text = shareData.text;
    const url = shareData.url;
    if (navigator.share) {
      navigator.share(shareData).then(() => { })
        .catch(console.error);
    } else if (Helper.isAndroidWebview()) {
      try {
        window.Android.enableShareMenu(title, text, url);
      } catch (error) {
        console.error(error);
      }
    } else if (Helper.isIOSWebview() && !window.MSStream) {
      try {
        window.webkit.messageHandlers.enableShareMenu.postMessage({ title, text, url });
      } catch (error) {
        console.error(error);
      }
    }
    else {
      this.renderModal(
        <Information
          title={Labels.shareService.copyAsk}
          confirmText={Labels.shareService.confirm}
          onClick={() => {
            this.refModal.current.closeModal();
            return this.copyLink(shareData);
          }}
        />
      );
    }
  }

  copyLink = (shareData) => {
    navigator.clipboard.writeText(shareData?.url);
    setTimeout(() => {
      this.renderInfoModal(Labels.shareService.copySuccess);
      // toast.success(Labels.shareService.copySuccess, Helper.toastOptions);
      // toast.warning(
      //   <div className="toast-title">
      //     Urgent: <span style={{ fontWeight: "normal" }}>Check your settings!</span>
      //   </div>,
      //   Helper.toastOptions
      // );
    }, 200);
  }

  renderItemImage = (item) => {
    const renderShareButton = () => {
      return (
        <div className="share-button-container me-2">
          <div role={'button'} className="share-icon d-flex" onClick={this.onClickShare}>
            <img src={GENERAL.share} alt="share-icon" />
          </div>
        </div>
      );
    };
    return (
      <div className="card-service-image-container details">
        <img
          src={item.image}
          alt="service_image"
          className={Helper.isLiteVersion() ? "pixelized" : ""}
          onError={(e) => (e.target.src = Helper.defaultImage())}
        />
        {renderShareButton()}
      </div>
    );
  }

  toggleShowFeedback = () => {
    this.setState(prevState => ({
      showFeedback: !prevState.showFeedback
    }));
  }

  resetFeedback = () => {
    this.setState(state => ({
      feedback: state.feedbackBD
    }));
    this.toggleShowFeedback();
  }

  renderCTAFeedbackContainer = () => {
    const { showFeedback } = this.state;

    if (!showFeedback) {
      return;
    }

    return (
      <div className="feedback-cta-container d-flex justify-content-between align-items-center mt-2">
        <div className="link-container me-5" role="button" onClick={this.resetFeedback}>
          <img src={GENERAL.arrowLeft} alt="icon-link" className="me-1" />
          <span>voltar atrás</span>
        </div>
        <Button className='cta-button-primary d-medium ms-1 px-5' type='button' value='Submeter Feedback'
          onClick={() => {
            this.validateSubmition();
          }} />
      </div>
    );
  }

  renderCTAContainer = () => {
    const {showFeedback } = this.state;
    const styleClass = !showFeedback ? 'd-none' : 'd-flex';
    return (
      <div className={`card-cta-container ${styleClass} mt-3 w-100 justify-content-between d-sm-flex`}>
        <div className='d-none d-sm-block w-100'>
          {this.renderLocationContainer()}
        </div>
        {this.renderCTAFeedbackContainer()}
      </div>
    );
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    const { state, item, feedback, stateAction, stateCheck, stateMessage, showUnreadCheck,
      showUnreadMessage, allowApprove, showInternalNotes } = this.state;

    return (
      <React.Fragment>
        <div className={`card-service-main-container p-3 w-100 m-0 h-auto mw-100`}>
          <ServiceNotificationWidgets
            data={{stateAction, stateCheck, stateMessage, source: 'details', showUnreadCheck, showUnreadMessage, allowApprove, showInternalNotes}}
            onRead={this.props.onReadMessage}
            onReadCheck={this.props.onReadCheck}/>
          {this.renderCardCorner(state.bars, feedback)}
          <div className='row'>
            <div className="col-12 col-sm-6">
              {this.renderItemImage(item)}
            </div>
            <div className="col-12 col-sm-6">
              {this.renderCardInformation()}
            </div>
          </div>
          {this.renderCTAContainer()}
        </div>
        <div className="mt-3 d-sm-none w-100">
          {this.renderLocationContainer()}
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }
}


export default ServiceDetailsCard;