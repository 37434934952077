import React from 'react';
import './MyAppointments.style.scss';
import { ASSETS, GENERAL } from '../../../assets/images/images';
import RightArrowButton from '../../button/rightArrowButton';
import Helper from "../../../core/helper/helper";
import DOMPurify from 'dompurify';
import { SERVICE } from '../../../assets/images/images';
import Button from '../../../components/button/button.jsx';

class AppointmentCard extends React.Component {
    state = {
      expanded: false,
    };
  
    handleToggleExpand = (event) => {
        event.stopPropagation();
        this.setState(prevState => ({
        expanded: !prevState.expanded,
      }));
    };

    renderCancelLink() {
        const { oldAppointments, onCancelAppointment } = this.props;
        return (
            <React.Fragment>
                {!oldAppointments && (
                    <span onClick={onCancelAppointment} className='link-container-span small'>Cancelar agendamento</span>
                )}
            </React.Fragment>)
    }

    renderRepeatLink() {
        const { oldAppointments, onRepeatAppointment } = this.props;
        const isGroup = this.props.appointment.is_group;
        return ( !isGroup &&
            <React.Fragment>
                {oldAppointments && (
                    <span onClick={onRepeatAppointment} className='link-container-span small'>Repetir agendamento</span>
                )}
            </React.Fragment>)
    }

    renderCalendarShareButton() {
        const { oldAppointments, onCalendarEventShare } = this.props;
        return (
            <React.Fragment>
                {!oldAppointments && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '250px' }}>
                            <Button
                                className='cta-button-secondary w-100'
                                type='iconAndText'
                                value='Adicionar a Outros Calendários'
                                icon={SERVICE.calendar}
                                onClick={() => onCalendarEventShare()}
                            />
                        </div>
                    </div>
                )
                }
            </React.Fragment >)
    }

    renderSymptomsAndObservations() {
        const { appointment } = this.props;
        return (
            <React.Fragment>
                <div className='card-text-subtitle'>
                    {appointment.symptoms[0] && (
                        <div> Serviços solicitados:<br />
                            <div className='elements pb-1'>
                                <ul className='mb-1'>
                                    {appointment.symptoms.map((symptom, index) => (
                                        <li key={index}>
                                            {symptom.name} {symptom.obs_value && `(${symptom.obs_value} ${symptom.obs_label})`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    {appointment.obs && (
                        <div className='pb-2'>
                            Os seus comentários/observações:<br />
                            <div className='elements' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(appointment.obs.replace(/\n/g, '<br>')) }} />
                        </div>
                    )}
                </div>
            </React.Fragment >)
    }

    renderEnterAppointmentButton() {
        return (
            <React.Fragment>
                <RightArrowButton className={'w-100'} role={'button'} onClick={this.props.onClick} />
            </React.Fragment>
        )
    }

    renderExpandButton() {
        const { expanded } = this.state;
        return (
            <React.Fragment>
                <div className='card-button-container' role={'button'} onClick={this.handleToggleExpand} >
                    <div className='simple-button'>
                        {expanded ? (<img src={GENERAL.iconSmallArrowUp} alt="Minimize" />)
                            : (<img src={GENERAL.iconSmallArrowDown} alt="Expand" />)}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderDates() {
        const { appointment } = this.props;
        const isOldDate = appointment.old_date != null && appointment.old_date !== appointment.date;
        return (
            <React.Fragment>
                <div className='date-container'>
                    {isOldDate &&
                        <>
                            <div className='smallIcon grey-blue'><img src={GENERAL.iconCalendarScheduled_oldDate} alt="old_date" /></div>
                            <div className='dates short'>
                                <div className='strikethrough'>{appointment.old_date}</div>
                            </div>
                        </>
                    }
                    <div className='smallIcon'><img src={GENERAL.iconCalendarScheduled} alt="date" /></div>
                    <div className='dates'>
                        <div>{appointment.date}</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderTimes() {
        const { appointment } = this.props;
        const isOldTime = appointment.old_time != null && appointment.old_time !== appointment.time;
        return (
            <React.Fragment>
                <div className='date-container'>
                    {isOldTime &&
                        <>
                            <div className='smallIcon grey-blue'><img src={GENERAL.iconClock_oldDate} alt="old_time" /></div>
                            <div className='dates short'>
                                <div className='strikethrough'>{appointment.old_time}</div>
                            </div>
                        </>
                    }
                    <div className='smallIcon'><img src={GENERAL.iconClock} alt="time" /></div>
                    <div className='dates'>
                        <div>{appointment.time}</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { appointment, oldAppointments } = this.props;
        const { expanded } = this.state;
        const cancelled = appointment.is_cancelled;
        const workAssociated = appointment.work_id;
        return (
            <div className={`card-appointment-main-container ${expanded ? 'expanded' : ''}`} role={'button'} onClick={(!expanded && !cancelled) ? this.props.onClick : undefined} style={{ zIndex: 1 }} >

                <div className='card-appointment-info-container' >
                    <div>
                        <div className='card-appointment-image-container'>
                            {oldAppointments ? <img src={Helper.urlItemImageByID(appointment.item_fo_id, appointment.company_id) || ASSETS.clientLogo} alt="company logo" onError={(e) => ((e.target).src = ASSETS.clientLogo)}/>
                                : <img src={Helper.isDemo()? GENERAL.defaultCompanyLogo : (Helper.urlCompanyImage(appointment.company_logo) || ASSETS.clientLogo)} alt="company logo" />}
                        </div>
                        <div className='card-text-subtitle text-center fw-bold'>
                            {Helper.isDemo(appointment.location)? "Empresa Demo" : appointment.location }<br />
                        </div>
                    </div>
                    <div className='card-appointment-text-container w-100'>
                        {cancelled && <div className='card-text-title text-danger' style={{ fontSize: '14px' }}>
                            CANCELADO<br />
                        </div>}
                        {!cancelled && oldAppointments && !workAssociated && <div className='card-text-title text-danger' style={{ fontSize: '14px' }}>
                            NÃO COMPARECEU<br />
                        </div>}
                        {appointment.item_title &&
                            <div className='card-text-title'>
                                {appointment.item_title}<br />
                            </div>
                        }
                        <div className='card-subtitle-container'>
                            <div className='card-text-subtitle'>
                                {appointment.item_subtitle}
                                {this.renderDates()}
                                {this.renderTimes()}
                            </div>
                            {this.renderExpandButton()}

                        </div>
                    </div>
                </div>
                <div>
                    {expanded && 
                        <>
                        {this.renderSymptomsAndObservations()}
                        {this.renderCalendarShareButton()}
                        {this.renderCancelLink()}
                        {this.renderRepeatLink()}
                        </>
                    }
                    {!expanded && 
                        this.renderEnterAppointmentButton()
                    }
                </div>

            </div>
        );
    }
  }

  export default AppointmentCard;