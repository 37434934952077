// React Imports
import React from 'react';
import Helper from '../../../core/helper/helper';
import RightArrowButton from '../../button/rightArrowButton';
import 'components/card/service/serviceCard.style.scss';
import FeedbackBoxCard from '../../feedback/feedbackBoxCard';

class ServiceFeedbackCard extends React.Component {
  constructor(props) {
    super(props);
    const { data: { item, state, dateEnd, feedback, valueRating, observations }, data } = this.props;
    this.state = {
      item: item,
      state: state,
      dateEnd: dateEnd,
      feedback: feedback,
      valueRating: valueRating,
      observations: observations,
      data
    };
  }

  renderItemInformation = () => {
    const { item, item: { title }, state: { bars }, dateEnd } = this.state;

    return (
      <div className='service-equipment-container' onClick={this.props.onClick}>      
        <div className='service-equipment-name text-truncate'>{ Helper.printValue(title) }</div>
        <div className='service-equipment-data-row d-flex'>
          <img src={ Helper.checkoutIcon(dateEnd, bars) } alt=""/>
          <div className='service-date-text'>
            { dateEnd }
          </div>

        </div>
        {this.renderCardImage(false, item)}
      </div>
    );
  }

  
  renderCardImage = (isCompact, item) => {
    return isCompact ? null : (
      <div className="card-service-image-container">
        <img
          src={item.image}
          alt="service_image"
          className={Helper.isLiteVersion() ? "pixelized" : ""}
          onError={(e) => (e.target.src = Helper.defaultImage())}
        />
      </div>
    );
  }

  render() {
    const { data: { feedback, valueRating, observation, id }} = this.props;
    return (
      <div className='card-service-main-container p-3' >
      { this.renderItemInformation() }

      <div className='mt-2 pt-1'>
        <FeedbackBoxCard data={{ feedback, valueRating, observation, id }} type="small"
         callback={this.props.callback}/>
      </div>

      <RightArrowButton black={true} onClick={this.props.onClick}/>
    </div>
    );
  }
}

export default ServiceFeedbackCard;
