import React, { Fragment, useEffect, useState } from "react";
import './CompanyCard.scss';
import { ASSETS, GENERAL } from "../../../assets/images/images";
import { CompanyCardPropTypes } from "../../../core/models/props-interface.d";
import Helper from "../../../core/helper/helper";
import LinkButton from "../../button/LinkButton/LinkButton";
import CompanyPhoneInfo from "./CompanyCardInfo/CompanyPhoneInfo";
import LocalData from "../../../core/localData";

/**
 * @param {Object} props
 * @param {Company} props.company - The company object.
 * @param {boolean=} props.isDetailsCard - (Optional) Whether it is a details card.
 * @param {(c: Company) => void} props.onSelect - A function to handle the click event.
 */
const ExpandedCompanyCard = (props) => {
  const { isDetailsCard, isDark, isCurrent} = props;
  const cardStyle = isDetailsCard ? 'w-100 h-auto d-inline-flex' : '';
  const [company, setPropValue] = useState(props.company);

  useEffect(() => {
    setPropValue(props.company);
  }, [props.company]);

  const handleOnSelect = () => {
    props.onSelect(company);
  }

  const renderNotiIcon = () => {
    const count = LocalData.companiesCount?.companies[company.domain];

    return !count || !isCurrent ? null : (
      <div className="tab-notification-container">
        <div className="tab-notification">
          <div className={`number ${count > 99 ? "small" : ""}`}> {count} </div>
        </div>
      </div>
    );
  };

  const renderLogoContainer = () => {
    const renderAddButton = () => {
      if (isCurrent || company?.registered) {
        return (<Fragment />);
      }
      return (
        <div className="add-button-container">
          <img src={GENERAL.iconPlus} alt="add-company" />
        </div>
      );
    }

    return (
      <div className="company-logo flex-center">
        {renderNotiIcon()}
        {!Helper.isDemo() && !company?.logoUrl ? (
          <div className="company-name-logo big">{company?.name}</div>
        ) : (
          <img
            src={
              Helper.isDemo() ? GENERAL.defaultCompanyLogo : company?.logoUrl || ASSETS.clientLogo
            }
            alt="company-logo"
            onError={(e) =>
              (e.target.src = Helper.isDemo() ? GENERAL.defaultCompanyLogo : ASSETS.clientLogo)
            }
          />
        )}
        {renderAddButton()}
      </div>
    );
  }

  const renderLocalization = () => {
    return !company?.town ? null : (
      <div
        className={`company-localization ${
          isDetailsCard ? "details" : ""
        } mt-1 d-inline-flex justify-content-between align-items-center`}
      >
        <div className="info-icon me-2">
          <img
            src={GENERAL.iconLocation}
            alt="address-icon"
            className={isDark ? 'filter-white' : ''}
          />
        </div>
        <LinkButton
          onClick={() => {}}
          styleClass={`link-container ${isDetailsCard ? "details" : ""}`}
          textClass="small"
          isMaps={isDetailsCard}
          valueLink={isDetailsCard ? company?.name + ", " + company?.address : undefined}
          valueText={company?.town}
          isDetailsCard={isDetailsCard}
        />
        {renderDistance()}
      </div>
    );
  }

  const renderDistance = () => {
    return !company?.distance ? null : (
      <div className="distance ms-1">
        {company?.distance ? Helper.formatDistance(company?.distance) : ""}
      </div>
    );
  };

  return (
    <div
      className={`company-card ${cardStyle}`}
      role="button"
      id="company-card"
      onClick={() => handleOnSelect()}
    >
      {renderLogoContainer()}
      <div className={`company-info ${isDetailsCard ? "ms-3" : ""}`}>
        <div className={`mt-1 company-name${isDetailsCard ? " details" : ""}`}>{Helper.isDemo(company?.name)? "Empresa Demo" : company?.name}</div>
        {renderLocalization()}
        <div className="mt-1">
          <CompanyPhoneInfo company={company} isDark={isDark} />
        </div>
      </div>
    </div>
  );
}
ExpandedCompanyCard.propTypes = CompanyCardPropTypes;
export default ExpandedCompanyCard;
