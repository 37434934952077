import React from 'react';
import Helper from '../../../core/helper/helper';
import Labels from '../../../variables/labels';
import '../appointment//MyAppointments.style.scss';
import { GENERAL } from '../../../assets/images/images';

function CheckInDate (props) {
  const { data: {bars, schedule, dateStart, old_date, time, old_time, dateStartState, isAppointment}} = props;
  const checkinIcon = Helper.checkinIcon(schedule, bars, dateStart);
  let dateClass = bars > 2 && bars < 5 ? 'service-date-text active flex-start' : 'service-date-text flex-start';
  const dateChanged = !!old_date && old_date !== dateStartState;
  const timeChanged = !!old_time && old_time !== time;

  const renderDate = () => {
    if (bars > 1 && bars < 3 && dateStart) {
      return (
        <div className="service-date-estimated-container">
          <div className='estimated-date-label w-100 d-flex flex-start'> {Labels.estimatedDate} </div>
          <div className='estimated-date-text w-100 flex-start'> {dateStart} </div>
        </div>
      );
    }
    return (
        <div>  
            {dateStartState}
        </div>
    );
  }
  
  const renderAppointmentDateTime = () => {
    return (
      <React.Fragment>

        <div className={`service-equipment-data-row service-date-text ${!dateChanged ? 'flexRight' : ''}`} style={{ marginLeft: 8, marginRight: 0 }}>
          {dateChanged &&
            <>
              <div className='smallIcon grey-blue'><img src={GENERAL.iconCalendarScheduled_oldDate} alt="old_date" /></div>
              <div className='strikethrough dates '>
                {!dateChanged ? '' : old_date}
              </div>
            </>
          }
          <>
            <div className='smallIcon'><img src={GENERAL.iconCalendarScheduled} alt="date" /></div>
              <div className='dates bold'>
                {dateStartState}
              </div>
          </>
        </div>

        <div className={`service-equipment-data-row service-date-text mx-1`}>
          {timeChanged &&
            <>
              <div className='smallIcon grey-blue'><img src={GENERAL.iconClock_oldDate} alt="old_time" /></div>
              <div className='strikethrough dates '>
                {!timeChanged ? '' : old_time}
              </div>
            </>
          }
          <>
            <div className='smallIcon'><img src={GENERAL.iconClock} alt="time" /></div>
            <div className='dates bold'>
              {time}
            </div>
          </>
        </div>
      </React.Fragment>
    );

  }

  return (
    <>
      {isAppointment &&
        <>
          {renderAppointmentDateTime()}
        </>
      }
      {!isAppointment &&
        <div className='service-equipment-data-row'>
          <img src={checkinIcon} alt="" />
          <div className={dateStart ? dateClass : 'service-date-text tbd'}>
            {renderDate()}
          </div>
        </div>
      }
    </>
  );
}

export default CheckInDate;