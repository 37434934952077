// React Imports
import React from 'react';
import {withRouter} from 'react-router';
import Cookies from 'universal-cookie';
// Components
import MenuButton from 'components/button/menuButton.jsx';
// Assets
import { SIDEBAR } from 'assets/images/images.js';
import Helper from '../../core/helper/helper';
import LocalData from '../../core/localData';
import { GENERAL } from '../../assets/images/images';
import Modal, { Confirmation, Information, VideoTutorial } from '../../components/modal/modal';
import Labels from '../../variables/labels';
import { deleteClientData } from '../../api/routes/api.route';

class Conta extends React.Component {

  cookies = new Cookies();
  refModal = React.createRef();

  handleEventVideoTutorial = () => {
    this.renderModal(
      <VideoTutorial
        title={"Tutorial Cliente"}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
        videoId="ZgPUE68tg-U" // Replace with your YouTube video ID
      />,
      false,
      true
    );
  };

  handleEventPerfil = () => {
    this.props.handleBackButton();
    this.props.history.push(`/perfil`);
  }

  handleEventChangePassword= () => {
    this.props.handleBackButton();
    this.props.history.push(`/alterar-password`);
  }

  handleEventFeedback= () => {
    this.props.handleBackButton();
    this.props.history.push(`/enviar-feedback`);
  }

  handleEventSuporte= () => {
    this.props.handleBackButton();
    this.props.history.push(`/suporte-cliente`);
  }
  
  handleEventDelete = () => {
    this.renderModal(
      <Confirmation
        title='Atenção'
        text={Labels.deleteAccount.confirmation.message(Helper.companyName())}
        confirmText={Labels.deleteAccount.confirmation.confirm + " " + Helper.companyName()}
        confirmationHandle={() => this.deleteClient()}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
    
  }

  deleteClient = () => {
    this.refModal.current.closeModal();
    deleteClientData(this.cookies.get('sessionToken')).then(res => {
      if (res) {
        if (res.code) {
          if (res.code === 409) {
            this.refModal.current.closeModal();
            this.props.handleBackButton();
            this.logoutClient(true);
          } else {
            this.renderGenericError();
          }
        } else {
          this.renderModal(
            <Information
              title={`Conta ${Helper.companyName()}`}
              text={res.data?.message}
              onClick={() => {
                this.refModal.current.closeModal();
                this.props.handleBackButton();
                this.logoutClient(true);
              }}
            />
          );
        }
      }
    });
  }
  logoutClient = (doNotMemorizeCurrentCompany = false) => {
    const eventInfo = {
      auth_token: this.cookies.get('sessionToken'),
      route: this.cookies.get('route'),
      client_route: this.cookies.get('clientroute')
    };
    if (Helper.isIOSWebview()) {
      try {   
        window.webkit.messageHandlers.logout.postMessage(eventInfo);
      } catch (error) {
        console.error(error);
      }
    }
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.logout(eventInfo.auth_token, eventInfo.route, eventInfo.client_route);
      } catch (error) {
        console.error(error);
      }
    }
    this.resetClientData();
    Helper.logout(doNotMemorizeCurrentCompany);
  }

  handleEventLogout = () => {
    this.renderModal(
      <Confirmation
        title='Atenção'
        text={"Deseja terminar a sua sessão na app Keymaster?"}
        confirmText={"Terminar Sessão"}
        confirmationHandle={() => this.logoutClient()}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()}/>
    );
  }

  renderModal = (view, isMedia = false, occupyScreen=false) => {
    this.refModal.current.renderView(view, isMedia, occupyScreen);
    this.refModal.current.openModal();
  }

  resetClientData() {
    this.cookies.remove('sessionToken', Helper.cookiesConfig);
    this.cookies.remove('liteToken', Helper.cookiesLiteConfig);
    this.cookies.remove('liteEmail', Helper.cookiesLiteConfig);
    this.cookies.remove('litePhone', Helper.cookiesLiteConfig);
    this.cookies.remove('userId', Helper.cookiesLiteConfig);
    this.cookies.remove('isCalToken', Helper.cookiesLiteConfig);
    LocalData.reset();
  }

  render() {
    return (
      <React.Fragment>
        <div className='side-bar-menu-container-block pt-4 max-column-width'>
          <div className="menu-info scrollable-container">
            <MenuButton
              icon={SIDEBAR.editProfileBlue}
              title='Editar Perfil' path={`/`}
              onClick={this.handleEventPerfil} />
            {LocalData.userDetails?.email_validated &&
              <MenuButton
                icon={SIDEBAR.lockerBlue}
                title='Alterar Password' path={`/`}
                onClick={this.handleEventChangePassword} />
            }
            <MenuButton
              icon={SIDEBAR.feedbackBlue}
              title='Enviar Feedback' path={`/`}
              onClick={this.handleEventFeedback} />
            <MenuButton
              icon={SIDEBAR.suporteBlue}
              title='Apoio ao Cliente' path={`/`}
              onClick={this.handleEventSuporte} />
            <MenuButton
              icon={GENERAL.deleteIcon}
              title = {`Apagar a minha conta em ${Helper.companyName()}`}
              path={`/`}
              onClick={this.handleEventDelete} />
            <MenuButton
              icon={SIDEBAR.videoDemoIcon}
              title='Vídeo de Demonstração' path={`/`}
              onClick={this.handleEventVideoTutorial} />
            <div className="fixed-buttons-wrapper mt-2" style={{ height: '100px' }}>
              <div className='cta-button-secondary mb-4 w-100 d-flex justify-content-center align-items-center'
                role="button"
                onClick={() => { this.handleEventLogout() }}>
                Terminar Sessão
              </div>
            </div>
          </div>
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }
}

export default withRouter(Conta);
