import React, { Component, createRef } from 'react';
import Labels from '../../../variables/labels';
import Helper from '../../../core/helper/helper';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/loader/loader';
import MyEquipments from '../myEquipments.view.jsx';
import AppointmentCard from '../../../components/card/appointment/appointmentCard.jsx';
import ScheduleService from '../../schedule/core/services/schedule.service.js';
import Modal, { Information, Confirmation, MultiOptions } from "../../../components/modal/modal";
import { GENERAL } from '../../../assets/images/images';
import CompanyDetailsInfo from '../MenuKey/ProvidersPage/CompanyDetails/CompanyDetailsInfo';
import { CompanyMapper } from '../../../core/models/company.model';
import LocalData from '../../../core/localData.js';
import { HelperSchedule } from '../../schedule/core/helpers/helperSchedule.js';
import Emitter from '../../../core/services.js';
import SidebarHelper from '../../../core/helper/sidebarHelper.js';
import AndroidBack from '../../../core/services/androidBack.service.js';
import IAgreeBox from '../../../components/iagree/iagreeBox.jsx';

const cLabels = {
  title: "Os seus agendamentos pendentes",
  title2: "Os seus agendamentos antigos",
  noAppointments: "Sem agendamentos pendentes!",
  noAppointments2: "Sem agendamentos antigos",
  errorEmptyAppointments: "Não foi possível aceder aos seus agendamentos.",
  chooseEquip: "Selecione o equipamento para o qual deseja realizar o seu agendamento:"
};
class MyAppointments extends Component {
  constructor(props) {
    super(props);
    this.refModal = createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    this.is_cal_token = this.isLiteVersion && new URLSearchParams(window.location.search).get('token_cal');
    this.state = {
      pendingAppointments: [],
      oldAppointments: [],
      showMyEquipments: false,
      showOldAppointments: false,
      isLoading: true,
      config: Helper.getConfig(),
    };
  }

  componentDidMount() {
    if (!this.isLiteVersion) this.getAppointments();
    this.setState({ isLoading: false });
    AndroidBack.setScheduleMenuOld(this.state.showOldAppointments);

    Emitter.on('OLD_APPOINTMENTS_VISIBLE_BACK', () => {
      this.handleClickToggleShowOldAppointments(this.state.showOldAppointments);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the isOpen state has changed
    if (this.state.showOldAppointments !== prevState.showOldAppointments) {
      // Call AndroidBack.setMenuOpen with the updated isOpen state
      AndroidBack.setScheduleMenuOld(this.state.showOldAppointments);
    }
  }

  componentWillUnmount() {
    AndroidBack.setScheduleMenuOld(false);
    Emitter.off('OLD_APPOINTMENTS_VISIBLE_BACK');
  }

  handleClickNewSchedule = (equipmentId) => {
    this.props.openDrawerView(); //toggle sidebar
    const token = LocalData.equipmentsToken !== '0' ? LocalData.equipmentsToken : '';
    const tokenCal = LocalData.equipmentsTokenCal !== '0' ? LocalData.equipmentsTokenCal : '';
    if (this.isLiteVersion) {
      this.props.history.push(`/schedule-lite/${equipmentId}${token ? `?token=${token}` : tokenCal ? `?token_cal=${tokenCal}` : ''}`);
    } else {
      this.props.history.push(`/schedule/${equipmentId}`);
    }
  };

  handleClickChooseEquipSchedule = () => {
    this.setState({ showMyEquipments: true });
  };

  renderNewScheduleButton = () => {
    const companyName = Helper.companyName().toUpperCase(); // Convert company name to uppercase
    return (
      <div className={`cta-bottom-container mt-5 w-100 px-1 flex-center ${this.is_cal_token? 'mb-5' : ''} `}>
        <div
          className="cta-button-primary w-100 d-flex justify-content-center align-items-center"
          role="button"
          onClick={this.handleClickChooseEquipSchedule}
        >
          Novo agendamento&nbsp;
          <div style={{ fontWeight: 'bold' }}>{companyName}</div>
        </div>
      </div>
    );
  };

  handleClickToggleShowOldAppointments = () => {
    this.setState(
      prevState => ({
        showOldAppointments: !prevState.showOldAppointments
      }),
      () => {
        // Scroll to the top of the container after state update
        const container = document.getElementById('my-appointments-container');
        if (container) {
          container.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    );
  };

  renderOldAppointmentsLink = () => {
    const { oldAppointmentsLink } = Labels.sidebarScheduleMessage();
    if (this.state.showOldAppointments) return null;
    return (
      <div className="link-container mt-4 mb-4 text-center">
        <span onClick={() => this.handleClickToggleShowOldAppointments()}>
          {" "}
          {/*TODO: Update state that hides the link, scrolls up, and shows previous appointments */}
          {oldAppointmentsLink}
        </span>
      </div>
    );
  }

  renderOldAppointments = () => {
    const { oldAppointments } = this.state;
    return (
      <div>
        {this.state.showOldAppointments && oldAppointments && (
          <div>
            {oldAppointments.map((appointment) => (
              <AppointmentCard
                key={appointment.id}
                appointment={appointment}
                onRepeatAppointment={() => this.handleRepeatAppointment(appointment)}
                oldAppointments={true}
                onClick={() => appointment.work_id
                  ? this.handlePressEvent("A-" + appointment.work_id, appointment)
                  : this.handlePressEvent("S-" + appointment.id, appointment)}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  filterNotSharedWorksFromAppointments = () => {
      const listWorks = LocalData.servicesData;
      const getFilteredIds = (worksArray) => {
        return worksArray.map(work => work.id.replace(/^[AC]-/, ''));
    }
    
    const progressWorksIds = listWorks.progressWorks ? getFilteredIds(listWorks.progressWorks) : [];
    const completedWorksIds = listWorks.completedWorks ? getFilteredIds(listWorks.completedWorks) : [];
    
    const combinedIds = [...progressWorksIds, ...completedWorksIds];
    
    //console.log("combinedIds: ", combinedIds);
    //console.log("pending: ", this.state.pendingAppointments);
    //console.log("history: ", this.state.oldAppointments);



    const { pendingAppointments, oldAppointments } = this.state;

    // Search for work_id in known services. If it does not exist, remove work_id from appointment
    const updatedPendingAppointments = pendingAppointments.map(appointment => {
        if (appointment.work_id && !combinedIds.includes(appointment.work_id)) {
            const { work_id, ...rest } = appointment;
            return rest;
        }
        return appointment;
    });

    const updatedOldAppointments = oldAppointments.map(appointment => {
        if (appointment.work_id && !combinedIds.includes(appointment.work_id)) {
            const { work_id, ...rest } = appointment;
            return rest;
        }
        return appointment;
    });

    this.setState({
        pendingAppointments: updatedPendingAppointments,
        oldAppointments: updatedOldAppointments
    });

    SidebarHelper.checkBoxShadow('my-appointments-container');   

  }

  getAppointments = async () => {
    if (this.isLiteVersion) {
      const { pending, history } = undefined;
      this.setState({
        pendingAppointments: pending,
        oldAppointments: history,
      });
    } else {
      if (LocalData.terms_accepted) {
        try {
          const { pending, history } = await ScheduleService.fetchScheduleAppointments();
          this.setState({
            pendingAppointments: pending,
            oldAppointments: history,
          });
          //this.filterNotSharedWorksFromAppointments(); //removed filter to enable showing "Não compareceu" on appointments without work_id
          Emitter.emit("APPOINTMENTS_COUNT");
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
      />
    );
  };

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  };

  handleRepeatAppointment = (repeatAppointment) => {
    const domain = repeatAppointment.domain;
    const url = `https://${domain}.keymaster.pt/client/schedule/${repeatAppointment.item_id}`;
    this.props.handleBackButton();

    localStorage.setItem('repeatAppointment', JSON.stringify(repeatAppointment));
    window.location.href = url;
  }

  handleCalendarEventShare = (appointment) => {
    if(Helper.isAndroidWebview()){
      HelperSchedule.createICalAndShare(appointment);
      return;
    }
    if(Helper.isIOSWebview()){
      HelperSchedule.createICalAndShare(appointment);
      return;
    }
    if(Helper.isAndroidBrowser() || Helper.isIOS()){
      HelperSchedule.createICalAndShare(appointment);
      return;
    }
    this.refModal.current.renderView(
      <MultiOptions
        title="Adicionar a Outros Calendários"
        option1Text="Calendário Google"
        icon1={GENERAL.iconGoogle}
        option2Text="Calendário Apple"
        icon2={GENERAL.iconApple}
        option3Text="Calendário Outlook"
        icon3={GENERAL.iconOutlook}
        handleOption1={() => {
          HelperSchedule.createGoogleCalendarUrl(appointment);
        }}
        handleOption2={() => {
          HelperSchedule.createICalAndShare(appointment);
        }}
        handleOption3={() => {
          HelperSchedule.createICalAndShare(appointment);
        }}
      />
    );
    this.refModal.current.openModal();
  };

  handleCancelAppointment = (appointmentId) => {
    this.refModal.current.renderView(
      <Confirmation
        title="Cancelar Agendamento"
        text="Deseja cancelar o agendamento?"
        cancelText="Voltar atrás"
        confirmationHandle={() => this.handleCancelAppointmentAction(appointmentId)}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
    this.refModal.current.openModal();
  };

  handleCancelAppointmentAction = (appointmentId) => {
    try {
      ScheduleService.deleteAppointment(appointmentId).then((response) => {
        if (response?.success) {
          this.getAppointments();
          this.refModal.current.closeModal();
        } else {
          this.renderInfoModal(response?.title, response?.body);
        }
      });
    } catch (error) {
      console.error("handleScheduleConfirmation [catch error]", error);
    }
  };

  renderAppointmentsSidebarTitles = () => {
    const { pendingAppointments } = this.state;
    if (!this.state.showMyEquipments && !this.state.showOldAppointments) {
      return (
        <>
          <div className="mb-4 menu-info-title"> {cLabels.title} </div>
          {!pendingAppointments?.length &&
            (
              <div className="menu-info-noAppointments">
                <div className="menu-info pt-4 pb-5 normal-light-white">
                  {cLabels.noAppointments}
                </div>
              </div>
            )}
        </>
      );
    }
    if (!this.state.showMyEquipments && this.state.showOldAppointments) {
      return (
        <>
          <div className="link-container mt-2 mb-4 text-center">
            <span
              onClick={() =>
                this.handleClickToggleShowOldAppointments()
              }
            >
              {" "}
              {/*TODO: Update state that hides the link, scrolls up, and shows previous appointments */}
              {"Voltar para agendamentos pendentes"}
            </span>
          </div>
          <div className="mb-4 menu-info-title"> {this.state.oldAppointments?.length ? cLabels.title2: cLabels.noAppointments2} </div>
        </>
      );
    }
    if (!this.state.showMyEquipments && !pendingAppointments?.length && !this.state.showOldAppointments) {
      return (
        <div className="menu-info-noAppointments">
          <div className="menu-info pt-4 pb-5 normal-light-white">
            {cLabels.noAppointments}
          </div>
        </div>
      );
    }
  }

  renderPendingAppointments = () => {
    const { pendingAppointments } = this.state;
    return (
      <React.Fragment>
        {pendingAppointments?.map((appointment) => (
          <AppointmentCard
            key={appointment.id}
            appointment={appointment}
            onCancelAppointment={() => this.handleCancelAppointment(appointment.id)}
            onCalendarEventShare={() => this.handleCalendarEventShare(appointment)}
            onClick={() => appointment.work_id
              ? this.handlePressEvent("A-" + appointment.work_id, appointment)
              : this.handlePressEvent("S-" + appointment.id, appointment)}

          />
        ))}
      </React.Fragment>
    );
  };

  /**
   * Evento de click do serviço selecionado
   *
   * @param {number} id - id do serviço
   * @param {string} info - objecto da serviço selecionado
   */
  handlePressEvent = (id, info) => {
    const domain = info.domain;
    const url = `https://${domain}.keymaster.pt/client/service-details/${id}?s=I&t=0`;
    this.props.handleBackButton();
    window.location.href = url;
  }

  renderLoader = () => {
    if (this.state.isLoading) {
      return <Loader message="" inverted={false} local={true} big={false}></Loader>;
    }
    return;
  };

  renderEquipments = () => {
    const { config } = this.state;
    if (config?.scheduler_module === "FO_ONLY") {
      // -> FO_ONLY
      const company = CompanyMapper.fromCompanyDto({
        name: config?.company_name,
        domain: config?.domain,
        logo_url: config?.logo,
        geo_coords: config?.geo_coords,
        phone: config?.whatsapp_number,
      });
      return (
        <div className="company-details-card-container">
          <div className="company-details-card mt-3 mt-md-4">
            <div className="company-details-content-container flex-column align-center">
              <div
                className="icon-close"
                role="button"
                onClick={() => this.setState({ showMyEquipments: false })}
              >
                <img src={GENERAL.iconClose} alt="icon-modal-close" />
              </div>
              <h2 className="text-center">
                {Labels.sidebarScheduleMessage(Helper.companyName())?.fullScheduleDesactivated}
              </h2>
              <p className="text-center">Contacte-nos via:</p>
              {/* <div className="company-details-content-separator"></div> */}
              <CompanyDetailsInfo company={company} />
            </div>
          </div>
        </div>
      );
    }
    return (
      <MyEquipments
        embeddedSchedule={true}
        handleClickNewSchedule={this.handleClickNewSchedule}
        {...this.props}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="side-bar-menu-container-block text-white mt-5 pt-1">
          {!LocalData.terms_accepted && <IAgreeBox url={Helper.addOrUpdateSearchParam(window.location, "openAppointment", true)} />}
          {(LocalData.terms_accepted || this.isLiteVersion) && (
            <>
              {!this.is_cal_token && <div className="menu-info"> {this.renderAppointmentsSidebarTitles()} </div>}
              <div
                className={`menu-info ${
                  !this.state.showOldAppointments
                    ? "scrollable-container short"
                    : "scrollable-container short2"
                }`}
                id="my-appointments-container"
              >
                {!this.is_cal_token && !this.state.showMyEquipments &&
                  !this.state.showOldAppointments &&
                  this.renderPendingAppointments()}
                {this.renderLoader()}
                {!this.state.showMyEquipments && !this.state.showOldAppointments && (
                  <div
                    className={`fixed-buttons-wrapper ${
                      this.state.pendingAppointments?.length > 4 ? "box-shadow" : ""
                    }`}
                  >
                    {this.renderNewScheduleButton()}
                    {!this.is_cal_token && this.renderOldAppointmentsLink()}
                  </div>
                )}
                {!this.state.showMyEquipments &&
                  this.state.showOldAppointments &&
                  this.renderOldAppointments()}
              </div>
              {this.state.showMyEquipments &&
                !this.state.showOldAppointments &&
                this.renderEquipments()}
            </>
          )}
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }
}

export default withRouter(MyAppointments);