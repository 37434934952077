// React Imports
import React from 'react';
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';
// Components
import CardEquipment from '../../components/card/equipment/equipmentCard.jsx';
import Toast from 'components/sidebar/toast/toast.js';
import Loader from '../../components/loader/loader';
// API Imports
import { getItemList, addItemToUser } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';
import Modal, { Information } from '../../components/modal/modal';
import Helper from '../../core/helper/helper';
import AddEquipment from '../../components/addEquipment/addEquipment';
import LocalData from '../../core/localData';
import IAgreeBox from '../../components/iagree/iagreeBox.jsx';
import Button from '../../components/button/button.jsx';


class MyEquipments extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.refToast = React.createRef();
    this.refPlateList = React.createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    this.state = {
      data: null,
      error: '',
      inputStatus: false,
      isLoading: LocalData.terms_accepted
    }
  }

  componentDidMount() {
    this.getItemListData();
  }

  /**
  * Pedido para retornar a lista de equipamentos do utilizador
  */
  getItemListData = () => {
    const isValidItem = (item) => {
      if (!item) return false;
      const keys = Object.keys(item);
      // Exclude `image` and check if there are any other properties
      const validKeys = keys.filter(key => key !== 'image');
      return validKeys.length > 0;
    };

    if (!this.isLiteVersion) this.callGetItemsApi();

    if (!LocalData.equipmentsData) {
      return;
    }

    const liteDataItem = isValidItem(LocalData.liteData?.item) ? LocalData.liteData?.item : null;
    if (liteDataItem) {
      let doNothingFlag = false;
      let id;
      let existingItem
      if (liteDataItem.fo_id) {
        existingItem = LocalData.equipmentsData.find(item =>
          item.id === liteDataItem.fo_id
        );
        if (existingItem) {
          doNothingFlag = true;
        } else {
          id = liteDataItem.fo_id;
        }
      } else {
        if (liteDataItem.title && liteDataItem.subtitle) {
          existingItem = LocalData.equipmentsData.find(item =>
            (item.title === liteDataItem.title && item.subtitle === liteDataItem.subtitle)
          );
          if (existingItem) {
            doNothingFlag = true;
          } else {
            id = Helper.getNextInternalEquipID();
          }
        }
      }
      if (!doNothingFlag) {
        const transformedItem = {
          id: id,
          title: liteDataItem.title,
          subtitle: liteDataItem.subtitle,
          photo: liteDataItem.photo,
          image: liteDataItem.image,
        };
        const updatedEquipmentsData = [...LocalData.equipmentsData, transformedItem];
        LocalData.equipmentsData = updatedEquipmentsData;
        const query = this.props.location.search;
        const token = new URLSearchParams(query).get('token');
        const tokenCal = new URLSearchParams(query).get('token_cal');
        if (token) { LocalData.equipmentsToken = token;}
        if (tokenCal) { LocalData.equipmentsTokenCal = tokenCal;}
        if(!token && !tokenCal) LocalData.equipmentsToken = 0;
      }
    } else {
      //TODO: Check if there is any item in showcase API
    }

    this.setState({
      data: LocalData.equipmentsData,
      isLoading: false,
    });
  }

  callGetItemsApi() {
    if (LocalData.terms_accepted) {
      getItemList(this.cookies.get("sessionToken")).then((value) => {
        if (value) {
          if (value.code) {
            this.setState({
              data: [],
              error: true,
              isLoading: false,
            });
          } else {
            this.setState({
              data: [],
              isLoading: false,
            });
            this.setState({
              data: value.items,
              error: false,
              isLoading: false,
            });
            LocalData.equipmentsData = value.items;
          }
        } else {
          this.setState({
            data: [],
            error: true,
            isLoading: false,
          });
        }
      });
    }
  }

  /**
  * Pedido para adicionar uma nova equipment
  *
  * @param {any} newItem - objecto com o valor da equipamento a inserir
  */
  callAddItem = (newItem) => {
    this.setState({ isLoading: true });
    addItemToUser(this.cookies.get('sessionToken'), newItem).then(value => {
      this.setState({ isLoading: false });
      if (value) {
        if (value.code) {
          if (value.code === 401) { return; }
          if (value.code === 409) {
            Modal.create(<Information title={Labels.registedErrorTitle} text={value.message} onClick={() => Modal.close()} />);
          } else if (value.code === 429) {
            Modal.create(<Information title={Labels.registedErrorTitle} text={value.message} onClick={() => Modal.close()} />);
          } else {
            Modal.create(<Information title={Labels.registedErrorTitle} text={Labels.genericErrorMessage} onClick={() => Modal.close()} />);
          }
        } else {
          Modal.create(<Information title={Labels.registedSuccessTitle} text={value.message} onClick={() => Modal.close()} />);
          this.getItemListData();
        }
      } else {
        Modal.create(<Information title={Labels.registedErrorTitle} text={Labels.genericErrorMessage} onClick={() => Modal.close()} />);
      }
      this.handleChangeInputEvent();
    });
  }

  /**
  * Visualizar details sobre a equipamento selecionada
  *
  * @param {string} id - id da equipment
  */
  handlePressEventDetails = (id) => {
    this.props.handleBackButton();
    if (this.props.embeddedSchedule) { 
      this.props.handleClickNewSchedule(id); 
    } else { 
      this.props.history.push(`/equipment-details/${id}`); 
    }
  }


  /**
  * Evento para activar o modo de adiçao de equipment
  */
  handleChangeInputEvent = () => {
    this.setState((prevState) => ({
      inputStatus: !prevState.inputStatus,
    }));
  }

  /**
  * Evento para renderizar o campo para adicionar items
  */
  changeInputRender = () => {
    if (this.state.inputStatus) {
      return (
        <div className="mt-2 mb-3 mb-md-0" style={{ width: '90%' }}>
          <AddEquipment
            onClick={(data) => this.handleEventAddItem(data)} isForm={true}>
          </AddEquipment>
        </div>
      );
    } else {
      return (
        <>
          <div className="cta-bottom-container mt-3 w-100 px-1 flex-center">
            <div className='cta-button-secondary mb-4 w-100 d-flex justify-content-center align-items-center'
              role="button"
              onClick={() => this.handleChangeInputEvent()}>
              {`+ Adicionar ${Helper.itemLabel()}`}
            </div>
          </div>
          {(this.isLiteVersion && LocalData.liteData?.is_client) &&
              <Button
                type="link"
                onClick={Helper.logoutFromLite}
                className='link-container mb-4'
                //textClass="small light"
                valueText={"Iniciar Sessão"}
              />
          }
        </>
      );
    }
  }

  /**
  * Validação do valor da matricula inserida
  *
  * @param {string} value - valor da matricula
  */
  plateValidater = (value) => {
    if (value.length < 8) {
      this.refToast.current.triggerToast("Formato Inválido", "alert");
      return false;
    }
    return true;
  }

  /**
  * Evento para validação da Equipamento a ser inserido
  */
  handleEventAddItem = (event) => {
    const fields = event;
    const newItem = Helper.buildEquipmentSpecsBody(fields);
    if (!this.isLiteVersion) {
      this.callAddItem(newItem);
    } else {
      const query = this.props.location.search;
      const token = new URLSearchParams(query).get('token');
      const tokenCal = new URLSearchParams(query).get('token_cal');
      Helper.buildLiteEquipmentData(fields, token, tokenCal);
      LocalData.liteItem = true;
      this.getItemListData();
      this.handleChangeInputEvent();
    }
  }

  /**
    * Apresentar lista de equipments
  */
  renderEquipmentEmpty = () => {
    const { data, error} = this.state;
    if (error) {
      return (<p className="emptyStateMessages"> { Labels.errorEmptyEquipments } </p>);
    } else if ((!data || data?.length <= 0) && this.props.embeddedSchedule) {
      return (<p className="emptyStateMessages">  
              {Labels.emptyEquipments1(Helper.itemLabel(), Helper.itemPronoun())}
              <br/>
              {Labels.emptyEquipments2(Helper.itemLabel(), Helper.itemPronoun())}
              </p>);
    } else if (this.props.embeddedSchedule){
      return (<p className="emptyStateMessages mb-3" style={{ width: '90%' }}>  
      {Labels.chooseEquipment(Helper.itemLabel(), Helper.itemPronoun())}
      </p>);
    }
  }

  /**
   * Representa a lista de equipamentos do Utilizador
  */
  renderListOfEquiments = () => {
    const { data } = this.state;
    if (!data) { return; }
    return (
      data.map((prop, key) => {
        return (
          <CardEquipment
            key={key}
            data={prop}
            onClickArea1={() => this.handlePressEventDetails(prop.id)}/>
        );
      })
    );
  }

  renderLoader = () => {
    if (this.state.isLoading) { return <Loader message='' inverted={false} local={true} big={false}></Loader>}
    return;
  }

  render() {
    const inputFieldsCount = Helper.itemFieldsToAdd().length;
    return (
      <div className={this.props.embeddedSchedule ? "" : "side-bar-menu-container-block mt-5 pt-1"}>
        <div
          className={`menu-info scrollable-container 
                        ${this.state.inputStatus ? "short3" : ""}
                        ${
                          this.state.inputStatus && inputFieldsCount === 2 ? "twoinputfields" : ""
                        }`}
        >
          {!LocalData.terms_accepted && <IAgreeBox url={Helper.addOrUpdateSearchParam(window.location, "openEquipments", true)} />}
          {(LocalData.terms_accepted || this.isLiteVersion) && (
            <>
              {this.renderLoader()}
              {this.renderEquipmentEmpty()}
              {this.renderListOfEquiments()}
              <div
                className={`fixed-buttons-wrapper ${
                  this.state.data?.length > 4 ? "box-shadow" : ""
                }`}
              >
                {this.changeInputRender()}
              </div>
            </>
          )}

          {/* {!this.state.data?.length ? null : <div className="d-none d-md-block scroll-base-container"></div>} */}
        </div>
        <Toast ref={this.refToast} />
      </div>
    );
  }
}

export default withRouter(MyEquipments);
