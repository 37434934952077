import React from "react";
import StarRatings from "react-star-ratings";
import Labels from "../../variables/labels";
import Button from '../button/button.jsx';

class FeedbackBoxCard extends React.Component {
  constructor(props) {
    super(props);
    this.refInput = React.createRef();
    const { data: { valueRating, observation, id }, type } = this.props;
    this.state = {
      observation,
      valueRating,
      id,
      dimension: type,
      isSmall: type === 'small',
      isMedium: type === 'medium',
    };
  }

  handleObsChange = (event) => {
    this.setState({ observation: event.target.value });
    const feedback = {
      classification: this.state.valueRating,
      observation: event.target.value, 
    }
    this.props.callback(feedback);
  }


  handleRatingValue = (value) => {
    this.setState({ valueRating: value });
    const feedback = {
      classification: value,
      observation: this.refInput.current.value, 
    }
    this.props.callback(feedback);
  }

  validateSubmition = () => {
    const feedback = {
      classification: this.state.valueRating,
      observation: this.state.observation, 
    }
    this.props.callback(feedback, true);
  }

  renderRatingContainer = (rating, id) => {
    const {isSmall, isMedium} = this.state;
    const starDimension = isSmall ? '18px' : isMedium ? '27px' : '40px';
    return(
      <div className={`rating-container ${isMedium ? '' : 'mt-0'}`}>
        <div className="rate-service">
          <StarRatings
            rating={rating}
            numberOfStars={5}
            name={`rating_${id}`}
            starRatedColor="#00A9FD"
            starEmptyColor="rgba(165, 165, 165, 0.4)"
            starHoverColor="#00A9FD"
            starDimension={starDimension}
            starSpacing="5px"
            svgIconViewBox="0 0 24 24"
            svgIconPath="m12 18.375-6.613 3.476 1.263-7.363L1.3 9.274l7.394-1.075L12 1.5l3.306 6.7L22.7 9.273l-5.35 5.214 1.264 7.363z"     
            height="16px"      
            changeRating={this.handleRatingValue}
          />
        </div>
      </div>
    );
  }

  renderSmallButton = (isSmall) => {
    if (!isSmall) {
      return <></>;
    }

    return (
      <div className="ms-2">
        <Button className='cta-button-secondary d-medium' type='button' value='Enviar'
        onClick={() => this.validateSubmition()}/>
      </div>
    );
  }

  render() {
    const { feedback, valueRating, observation, id, isSmall, dimension } = this.state;
    const rows = isSmall ? 1 : 4;
    const renderFeedbackTitle = () => {
      const titleText = () =>{
        if (feedback) { 
          return <span> { Labels.feedbackTitle } </span>
        } else {
          return <span> {Labels.feedbackMessage } </span>
        }
      }
      return !isSmall? null : (<p className="card-service-feedback-text m-0 mt-1 ">{titleText()}</p>);
    }
   
    return (
      <div className={`card-service-feedback-container ${!isSmall ? 'no-card' : ''}`}>
        {this.renderRatingContainer(valueRating, id)}
        { renderFeedbackTitle(feedback) }
        <div className={`text-area-container ${isSmall ? 'pt-2' : 'mt-3'}`}>
          <textarea ref={this.refInput} defaultValue={observation} maxLength="2000" onChange={this.handleObsChange}
            rows={rows} className={`text-area-feedback ${dimension}`} placeholder='Observações' />
          {this.renderSmallButton(isSmall)}
        </div>
      </div>
    );
  }
}
export default FeedbackBoxCard;