// React Imports
import React from 'react';
import Helper from '../../../core/helper/helper';
// core
import Labels from '../../../variables/labels';

class CookiesPageSample extends React.Component {
  renderPolicy = () => {
    return (
      <div className="modal-section">
        <div className="modal-title-text">{Labels.cookies.page.policy.title}</div>
        <div className="modal-sub-title-text" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: Labels.cookies.page.policy.subTitle}}></div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: Labels.cookies.page.policy.content}}></div>
      </div>
    );
  }

  
  renderInterpretationDefinitions = () => {
    const definitionsContentB = Labels.cookies.page.definitions.contentB.replaceAll('{0}', Helper.getCompanyWebsite())
    return (
      <div className="modal-section">
        <div className="modal-title-text">{Labels.cookies.page.definitions.title}</div>
        <div className="modal-sub-title-text">{Labels.cookies.page.definitions.subTitleA}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: Labels.cookies.page.definitions.contentA}}></div>
        <div className="modal-sub-title-text">{Labels.cookies.page.definitions.subTitleB}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: definitionsContentB}}></div>
      </div>
    );
  }

  renderUsage = () => {
    return (
      <div className="modal-section">
        <div className="modal-title-text">{Labels.cookies.page.usage.title}</div>
        <div className="modal-sub-title-text">{Labels.cookies.page.usage.subTitleA}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: Labels.cookies.page.usage.contentA}}></div>
        <div className="modal-sub-title-text">{Labels.cookies.page.usage.subTitleB}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: Labels.cookies.page.usage.contentB}}></div>
        <div className="modal-sub-title-text">{Labels.cookies.page.usage.subTitleC}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: Labels.cookies.page.usage.contentC}}></div>
        <div className="modal-sub-title-text">{Labels.cookies.page.usage.subTitleD}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: Labels.cookies.page.usage.contentD}}></div>
      </div>
    );
  }



  render() {
    return (
      <div className="modal-area-container">
        {this.renderPolicy()}
        {this.renderInterpretationDefinitions()}
        {this.renderUsage()}
      </div>
    );
  }
}


export default CookiesPageSample;
