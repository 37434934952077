import React from 'react';
import ProgressBar from '../../progressBar/progressBar';
import CheckInDate from './checkInDate';
import CheckOutDate from './checkOutDate';
import CheckOutInfo from './checkOutInfo';

const CardServiceState = (props) => {
  const {data: { dateStart, old_date, time, old_time, schedule, dateEnd, state, dateStartState, dateEndState, checkoutInfo, isAppointment, is_cancelled, is_history, missedAppointment }, isSmall, styleClass} = props;
  const { bars } = state;
  
  const renderCheckinDate = () => {
    return <CheckInDate data={{bars, dateStart, old_date, time, old_time, schedule, dateStartState, isSmall, isAppointment}}/>
  }

  const renderCheckoutDate = () => {
    if (isAppointment) return;
    return <CheckOutDate data={{bars, dateEnd, dateEndState}}/>;
  }
  const renderDates = () => {
    return (
      <div className='service-equipment-data'>
        {renderCheckinDate()}
        {renderCheckoutDate()}
      </div>
    );
  }

  const renderCheckoutInfo = () => {
    if (!checkoutInfo) {
      return <></>;
    }
    return (
      <div className='service-equipment-data my-1 justify-content-end'>
        <CheckOutInfo data={{ checkoutInfo }}/>
      </div>
    );
  }


  return (
    <div className={`card-service-state-container ${styleClass || ''}`}>
      <div className='card-service-info-container'>
        { renderDates() }
        { renderCheckoutInfo() }
        <ProgressBar small={isSmall} levels="5" state={state} is_cancelled={is_cancelled} isAppointment={isAppointment} missedAppointment={missedAppointment} is_history={is_history} />
      </div>
    </div>
  );
}

export default CardServiceState;