import React from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../core/helper/helper';
import Logo from '../logo/Logo';
import PoweredBy from '../logo/PoweredBy';

import './header.style.scss';

function Header() {
  const isDadosClienteUrl = window.location.pathname.includes('dados-cliente');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  // Get all parameters
  const allParams = {};
  urlParams.forEach((value, key) => {
    allParams[key] = value;
  });

  return (
    <div className="header w-100 d-flex flex-row justify-content-between">
      <div className="logo-container d-flex flex-column">
        <div className="company-logo mt-2 mx-3 mx-md-4" role={'button'}>
          {!isDadosClienteUrl && (<Link to={Helper.isLiteVersion() ? `/services-lite${queryString}` : `/services`} {...allParams}><Logo styleClass="mt-2" /></Link>)}
          {isDadosClienteUrl && (<Logo styleClass="mt-2" />)}
        </div>
      </div>
      <div className="app-logo-container me-3 me-md-4 align-items-center">
        <PoweredBy />
      </div>
    </div>
  );
}

export default Header;

