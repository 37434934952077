// React Imports
import React from 'react';
import Emitter from 'core/services.js';
// Style
import 'components/list/grid/grid.style.scss';
// Assets
import { GENERAL } from 'assets/images/images.js';
import CommentBox from '../../card/CommentBox/CommentBox';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shown: this.props.status,
    };
  }

  componentDidMount() {
    Emitter.on('LIST_GRID_STATE_CHANGED', (type) => this.forceToggle(type));
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props;
   
    if (status !== prevProps.status) {
      this.setState({shown: status})
    }
  }


  componentWillUnmount() {
    Emitter.off('LIST_GRID_STATE_CHANGED');
  }

  forceToggle(type) {
    if (type === this.props.type) {
      this.toggle();
    }
  }

  saveGridStatus(state) {
    sessionStorage.setItem(`${this.props.type}GridState`, state);
  }

  toggle = ()=> {
    if (this.props.type) {
      this.saveGridStatus(!this.state.shown);
    }
    this.setState({
      shown: !this.state.shown,
    });
  }

  renderGridContainer = () => {
    return (
      <div className={this.state.shown ? `grid-container ${this.props.hasObservationItem ? 'observation pt-0' : ''}` : `grid-container-closed `}
        style={{ justifyContent: this.props.center ? 'center' : '' }}>
          <div className="children-container w-100">
            {this.props.children}
            {this.renderObservations()}
          </div>
      </div>
    );
  }

  renderObservations = () => {
    const { obsData, obsType, onSaveObservation } = this.props;
    if (!this.props.hasObservationItem) {
      return;
    }

    return (
      <CommentBox
        saveObservation={onSaveObservation}
        observations={obsData.observations}
        obsType={obsType}
        readOnly={obsData.readOnly}
        noClickAction={obsData.noClickAction}
      />
    );
  }

  renderIcon = () => {
    const { icon, hasObservationItem, hasSymptoms } = this.props;
    if (!icon) { return; }
    let renderIcon = hasObservationItem && hasSymptoms && !this.state.shown ? GENERAL.warning : icon;
    return <img className="d-none d-lg-block icon me-2" src={renderIcon} alt="" />
  }

  renderToggleButton() {
    return <img className="open-grid-button my-0" role={'button'} src={this.state.shown ? GENERAL.iconSmallArrowUp : GENERAL.iconSmallArrowDown} alt="" />;
  }

  render() {
    const { styleClass, id } = this.props;
    const { shown } = this.state;
    return (
      <div className={`row-list-container container-kss  ${shown ? 'open' : ''} ${styleClass || ''}`} id={id}>
        <div className="row-list-title-container flex-between pe-3 py-2 py-md-3 ps-3 ps-md-4"
          onClick={() => this.toggle()}>
          <div className='d-flex'>
            {this.renderIcon()}
            <span>{this.props.title}</span> 
          </div>
          {this.renderToggleButton()}
        </div>
        { this.renderGridContainer() }
      </div>
    );
  }
}

export default Grid;
