import Login from '../views/Authentication/login.view.jsx';
import RecuperarPassword from '../views/Authentication/recuperarPassword.view.jsx';
import DefinirPassword from '../views/Authentication/definirPassword.view.jsx';
import Registo from '../views/Authentication/registo.view.jsx';
import DadosCliente from '../views/Authentication/dadosCliente.view.jsx';
import SendEmailRegisto from '../views/Authentication/sendEmailRegisto.view.jsx';
import SendEmailPassword from '../views/Authentication/sendEmailRecpPassword.view.jsx';
import PreSignUp from '../views/Authentication/preSignup.view.jsx';
import ConfirmEmailRegisto from '../views/Authentication/emailConfirmacao.view.jsx';

import Service from '../views/Services.view.jsx';
import ChangePassword from '../views/ChangePassword.view.jsx';
import EquipmentDetails from '../views/Details/equipmentDetails.view.jsx';
import ServiceDetails from '../views/Details/serviceDetails.view.jsx';
import FeedbackApp from '../views/Feedback/feedbackApp.jsx';
import SuporteCliente from '../views/Feedback/suporteCliente.jsx';
import SchedulePage from '../views/schedule/SchedulePage.jsx';
import HelpDeleteAccount from '../views/Authentication/HelpDeleteAccount.jsx';


const authenticatedRoutes = [
  { path: `/services`, name: 'Services', component: Service },
  { path: `/equipment-details/:id`, name: 'EquipmentDetails', component: EquipmentDetails },
  { path: `/service-details/:id`, name: 'ServiceDetails', component: ServiceDetails },
  { path: `/perfil`, name: 'Perfil', component: DadosCliente },
  { path: `/dados-cliente`, name: 'DadosCliente', component: DadosCliente },
  { path: `/alterar-password`, name: 'ChangePassword', component: ChangePassword },
  { path: `/enviar-feedback`, name: 'FeedbackApp', component: FeedbackApp },
  { path: `/suporte-cliente`, name: 'SuporteCliente', component: SuporteCliente },
  { path: `/help-delete-account`, name: 'HelpDeleteAccount', component: HelpDeleteAccount },
  { path: `/schedule-lite/:id`, name: 'SchedulePage', component: SchedulePage },
  { path: `/services-lite`, name: 'Services', component: Service },
  {
    redirect: true, path: '/', to: `/services`, name: 'Services',
  },
];

const nonAuthenticatedRoutes = [
  { path: `/schedule/:id`, name: 'SchedulePage', component: SchedulePage },
  { path: `/schedule-lite/:id`, name: 'SchedulePage', component: SchedulePage },
  { path: `/services-lite`, name: 'Services', component: Service },
  { path: `/service-details-lite`, name: 'ServiceDetails', component: ServiceDetails },
  { path: '/login', name: 'Login', component: Login },
  { path: `/definir-password/:token`, name: 'DefinirPassword', component: DefinirPassword },
  { path: `/recuperar-password`, name: 'RecuperarPassword', component: RecuperarPassword },
  { path: `/send-email-password`, name: 'SendEmailPassword', component: SendEmailPassword },
  { path: '/registo', name: 'Registo', component: Registo },
  { path: `/send-confirm-email`, name: 'SendEmailRegisto', component: SendEmailRegisto },
  { path: `/confirm-email/:token`, name: 'ConfirmEmailRegisto', component: ConfirmEmailRegisto },
  { path: `/pre-signup`, name: 'PreSignUp', component: PreSignUp },
  { path: `/help-delete-account`, name: 'HelpDeleteAccount', component: HelpDeleteAccount },
  {
    redirect: true, path: '/', to: '/login', name: 'Login',
  },
];

export {
  nonAuthenticatedRoutes,
  authenticatedRoutes,
};
