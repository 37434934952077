// React Imports
import React from 'react';
// Components
import Button from 'components/button/button.jsx';
import Helper from '../../../core/helper/helper';
import Labels from '../../../variables/labels';

class CookiesBannerSample extends React.Component {
  render() {
    return (
      <div className="modal-area-cookies-container">
        <div className="modal-message-container">
          { Helper.getCookiesMessage() }
          <div className="link-container" role={'button'} onClick={() => this.props.learnMoreHandle()}> 
              { Labels.cookies.learnMoreLink }
          </div>
        </div>
        <div className="modal-action-container" style={{minWidth: '150px'}}>
          <Button className="cta-button-primary d-medium w-100" type="button" value="OK"
            onClick={() => this.props.confirmationHandle()} />
        </div>
      </div>
    );
  }
}

export default CookiesBannerSample;
