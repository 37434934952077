import React, { Component, Fragment, createRef } from "react";
import { withRouter } from "react-router-dom";
import "./Schedule.scss";
import Step1Schedule from "./components/stepsContent/Step1Schedule";
import ScheduleService from "./core/services/schedule.service";
import ScheduleTopInformation from "./components/ScheduleTopInformation";
import Step2Schedule from "./components/stepsContent/Step2Schedule";
import ScheduleCTA from "./components/ScheduleCTA";
import Step3Schedule from "./components/stepsContent/Step3Schedule";
import ScheduleConfirmation from "./components/ScheduleConfirmation";
import Modal, { Confirmation, Information } from "../../components/modal/modal";
import Helper from "../../core/helper/helper";
import { format, addDays, subDays, startOfMonth, endOfMonth, addMonths, subMonths } from "date-fns";
import Emitter from "../../core/services";
import MediaFiles from "../../components/mediaFiles/MediaFiles";
import Labels from "../../variables/labels";
import Loader from "../../components/loader/loader";
import { addMediaFile, removeMediaFile } from "../../api/routes/api.route";
import Cookies from 'universal-cookie';
import LocalData from "../../core/localData";
import LoginModal from "../../components/modal/LoginModal";

const preferedTimeOptions= [
  {
      "value": 1,
      "label": "Manhãs"
  },
  {
      "value": 2,
      "label": "Tardes"
  },
  {
      "value": 3,
      "label": "Todo o dia"
  }
];

class SchedulePage extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    const query = this.props.location.search;
    this.token = new URLSearchParams(query).get('token') || LocalData.liteData?.token;
    this.token_cal = new URLSearchParams(query).get('token_cal') || LocalData.liteData?.token_cal;

    this.savedState = localStorage.getItem('appState');
    if (this.savedState && JSON.parse(this.savedState).data?.domain === Helper.companyDomain()) {
      const { data, timestamp } = JSON.parse(this.savedState);
      const currentTime = new Date().getTime();
      // Check if the saved state is still valid (within 1 hour)
      if (currentTime - timestamp < 3600000) {
        if (data?.scheduleData?.selectedDate) { data.scheduleData.selectedDate = new Date(data.scheduleData.selectedDate);}
        if (data?.scheduleData?.displayedDate) { data.scheduleData.displayedDate = new Date(data.scheduleData.displayedDate);}
        this.state = data;  // Set the state from the saved data
      } else {
        this.state = this.loadDefaultState();
      }

      // Clear the state from localStorage after usage
      localStorage.removeItem('appState');
    } else {
      this.state = this.loadDefaultState();
    }
  }

  loadDefaultState() {
    return {
      domain: Helper.companyDomain(),
      token: this.token,
      token_cal: this.token_cal,
      showLoginModal: false,
      actionCloseGallery: false,
      config: Helper.getConfig(),
      scheduleConfirmed: false,
      /** @type {1 | 2 | 3} */
      stepActive: 1, // 1 | 2 | 3
      /** @type {{ id: string, name: string, active: boolean}[]} */
      services: undefined,
      /** @type {SchedulerConfig} */
      schedulerConfig: undefined,
      /**@type {scheduleData} */
      scheduleData: {
        schedulerId: undefined,
        draftWorkID: undefined,
        itemDetails: undefined,
        selectedServices: [],
        serviceObservations: undefined,
        selectedDate: undefined,
        preferedTime: {
          "value": 3,
          "label": "Todo o dia"
        },
        selectedTime: undefined,
        timeSlots: undefined,
        displayedDate: new Date(),
        expertSelected: undefined,
        considerFavoriteExpert: true,
        expertsBySlot: undefined,
        files: {
          "provider": {
            "media": [],
            "documents": []
          },
          "customer": {
            "media": [],
            "documents": []
          }
        }
      }
    };
  }

  componentDidMount() {
    if (this.state.config?.scheduler_module !== 'FULL') {
      if (!this.isLiteVersion) {
        this.props.history.push('/');
      } else if (this.token) {
        this.props.history.push(`/services-lite?token=${this.token}`);
      } else if (this.token_cal) {
        this.props.history.push(`/services-lite?token_cal=${this.token_cal}`);
      } else {
        this.props.history.push('/services-lite');
      }
    }
    Emitter.emit('TOAST_LITEVERSION_DISABLE', true);
    // Check if the data exists in sessionStorage
    if (localStorage.getItem('repeatAppointment')) {
      // Retrieve the repeatAppointment object from sessionStorage
      const repeatAppointmentString = localStorage.getItem('repeatAppointment');
      const repeatAppointment = JSON.parse(repeatAppointmentString);
      // Delete the key from sessionStorage
      localStorage.removeItem('repeatAppointment');
      this.setState(prevState => ({
        stepActive: 1,
        scheduleData: {
          ...prevState.scheduleData,
          selectedServices: repeatAppointment.symptoms.slice(),
          serviceObservations: repeatAppointment.obs,
        },

      }), () => {
        this.getItemDetails();
      });
    } else {
      // Handle the case when the data is not found in sessionStorage
      this.getItemDetails();
    }

    this.createDraftSchedule();
    
    Emitter.on('SCHEDULE_BACK', () => {
      const { stepActive } = this.state;
      if (stepActive === 1) {
        window.history.back();
      } else {
        this.handleStepChanged(stepActive - 1);
      }
    });

    //If url has submit=true , submit the schedule automatically.
    const query = this.props.location.search;
    if (new URLSearchParams(query).get('submit') === 'true') {
      this.handleScheduleConfirmation();
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the URL has changed
    if (this.props.location !== prevProps.location) {
      // URL has changed, reload or reinitialize component logic
      this.handleUrlChange();
    }
  }

  handleUrlChange() {
    // Check if the data exists in sessionStorage
    if (localStorage.getItem('repeatAppointment')) {
      // Retrieve the repeatAppointment object from sessionStorage
      const repeatAppointmentString = localStorage.getItem('repeatAppointment');
      const repeatAppointment = JSON.parse(repeatAppointmentString);
      // Delete the key from sessionStorage
      localStorage.removeItem('repeatAppointment');
      this.setState(prevState => ({
        stepActive: 1,
        scheduleData: {
          ...prevState.scheduleData,
          selectedServices: repeatAppointment.symptoms.slice(),
          serviceObservations: repeatAppointment.obs,
        },

      }), () => {
        this.getItemDetails();
      });
    } else {
      // Handle the case when the data is not found in sessionStorage
      this.getItemDetails();
    }

    if (!this.state.scheduleData?.schedulerId || !this.state.scheduleData?.schedulerId){
      this.createDraftSchedule();
    }
  }

  componentWillUnmount() {
    Emitter.off('SCHEDULE_BACK');
  }

  getAppointmentFiles = async () => {
    const {schedulerId, draftWorkID} = this.state.scheduleData;
    const isDraft = true;
    try {
      const response = await ScheduleService.fetchAppointmentFiles("S-" + schedulerId, draftWorkID, isDraft);
      if (response?.data) {
        this.setState(prevState => ({
          scheduleData: {
            ...prevState.scheduleData,
            files: {
              provider: response.data.company_extras,
              customer: response.data.client_extras
            }
          }
        }));
      } else {
        this.renderInfoModal(response?.title, response?.body, response?.code);
      }
    } catch (error) {
      console.error('fetchAppointmentFiles [catch error]', error);
    }
  };

  createDraftSchedule = async () => {
    const {scheduleData} = this.state;
    if (scheduleData?.schedulerId && scheduleData?.draftWorkID) {
      return null;
    }
    try {
      const response = await ScheduleService.postCreateDraftSchedule(scheduleData);
      if (response) {
        this.setState(prevState => ({
          scheduleData: {
            ...prevState.scheduleData,
            schedulerId: response.scheduler_id,
            draftWorkID: response.draft_work_id
          }
        }));
      } else {
        this.renderInfoModal(response?.title, response?.body, response?.code);
      }
    } catch (error) {
      console.error('handleScheduleConfirmation [catch error]', error);
    }
  };
  
  getItemDetails = async () => {
    try {
      const equipId = this.props.match.params.id;
      if (!this.isLiteVersion) {
        if (this.state.scheduleData?.itemDetails) {
        } else {
          const itemDetails = await ScheduleService.fetchItemDetails(this.props.match.params.id);
          this.setState({ scheduleData: { ...this.state.scheduleData, itemDetails } });
          //this.addMockExpertFavorites();
        }
      } else {
        if (LocalData.equipmentsData?.length > 0 && equipId) {
          const matchedItem = LocalData.equipmentsData.find(item => item.id === equipId);
          if (matchedItem) {
            const { id, title, subtitle, image, photo, ...specs } = matchedItem;

            const itemDetails = {
              itemFoId: id,
              title,
              subtitle,
              image,
              photo,
              ...specs
            };
          
            this.setState({ scheduleData: { ...this.state.scheduleData, itemDetails } });
          } else {
            if (this.token) {
              this.props.history.push(`/services-lite?token=${this.token}`);
            } else if (this.token_cal) {
              this.props.history.push(`/services-lite?token_cal=${this.token_cal}`);
            } else {
              this.props.history.push('/services-lite');
            }
          }
        } else {
          if (this.token) {
            this.props.history.push(`/services-lite?token=${this.token}`);
          } else if (this.token_cal) {
            this.props.history.push(`/services-lite?token_cal=${this.token_cal}`);
          } else {
            this.props.history.push('/services-lite');
          }
        }
      }
      this.getServices();
    } catch (error) {
      console.error(error);
    }
  }
  
  getExpertsBySlot = async () => {
    try {
      const {scheduleData} = this.state;
      const expertsBySlot = await ScheduleService.fetchExpertsBySlot(scheduleData);
      this.setState({ scheduleData: { ...this.state.scheduleData, expertsBySlot } });
    } catch (error) {
      console.error(error);
    }
  }

  addMockExpertFavorites = () => { 
    const users = [
      "1"
    ];
  
    // Retrieve the current itemDetails from the state
    const itemDetails = this.state.scheduleData.itemDetails;
  
    // Create a new favorites array combining old favorites and mockFavorites
    const updatedFavorites = [...itemDetails.users, ...users];
  
    // Update the state with the new favorites array
    this.setState(prevState => ({
      scheduleData: {
        ...prevState.scheduleData,
        itemDetails: {
          ...prevState.scheduleData.itemDetails,
          users: updatedFavorites
        }
      }
    }));
  }

  addMockSpecialistsToServices = () => {
    // Logging initial services

    // Define the experts for the respective IDs
    const expertsMapping = {
        351: [
            { id: 1, name: "Joaquim Fernandes" },
            { id: 2, name: "Clara Costa" }
        ],
        452: [
            { id: 3, name: "Isabel Lopes" },
            { id: 2, name: "Clara Costa" }
        ]
    };

    // Update the services in the state
    const updatedServices = this.state.services.map(service => {
        // If the service ID is in the expertsMapping, add the experts array
        if (expertsMapping[service.id]) {
            return { ...service, users: expertsMapping[service.id] };
        }
        // Otherwise, return the service as is
        return service;
    });

    // Update the state with the modified services
    this.setState({ services: updatedServices });
};

  getServices = async () => {
    try {
      const services = await ScheduleService.fetchScheduleServices();
      this.setState({ services });
      //this.addMockSpecialistsToServices();
    } catch (error) {
      console.error(error);
    }
  };

  handleSelectedServices = (services) => {
    const selectedServices = services?.filter((s) => s.active);
    this.setState({
      scheduleData: {
        ...this.state.scheduleData,
        selectedServices,
      },
      considerFavoriteExpert: true
    });
  };
  /**
   * 
   * @param {{ id: string, name: string, active: boolean}[]} services 
   * @param { timePreference: int}
   * @param { displayedDate: date}
   */
  getSchedulerConfig = async (services, timePreference, displayedDate) => {
    const symptoms = services?.map(s => s?.id);
    let start = subDays(startOfMonth(subMonths(displayedDate, 1)), 8);
    if (start < new Date()) start = new Date();
    start = format(start, 'dd/MM/yyyy');
    let end = addDays(endOfMonth(addMonths(displayedDate, 1)), 8);
    if (end < new Date()) end = new Date();
    end = format(end, 'dd/MM/yyyy');

    if (symptoms.length===0) return;
    try {
      const schedulerConfig = await ScheduleService.fetchSchedulerConfig(symptoms, timePreference, start, end, this.state.scheduleData.expertSelected?.id);
      this.setState({ schedulerConfig });
    } catch (error) {
      console.error(error);
    }
  };

  handleSelectedExpert = (expertSelected) => {
    this.setState(prevState => ({
      scheduleData: {
        ...prevState.scheduleData,
        expertSelected: expertSelected  // Update expertSelected with the new value
      }
    }));
  };

  handleNewObservations = (serviceObservations) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, serviceObservations },
    });
  };

  clientDataUpdated = (newData) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, client: newData },
    });
  }

  handleSelectedDate = (selectedDate, preferedTime) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, preferedTime, selectedDate, selectedTime: undefined, timeSlots: undefined},
    });
  };

  handleChangeDisplayedDate = (displayedDate) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, displayedDate},
    });
    this.getSchedulerConfig(this.state.scheduleData.selectedServices, this.state.scheduleData.preferedTime.value, displayedDate);
  }

  handlePreferedTime = (preferedTime) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, preferedTime, selectedTime: undefined},
    });
    this.getSchedulerConfig(this.state.scheduleData.selectedServices,preferedTime.value, this.state.scheduleData.displayedDate);
  };

  handleSelectedTime = (selectedTime, timeSlots) => {
    this.setState({
      scheduleData: {
        ...this.state.scheduleData,
        selectedTime,
        timeSlots
      },
    });
  };
  
  updateServicesClearTemporary = (callback) => {
    const { selectedServices } = this.state.scheduleData;
    const hasServiceWithExpert = selectedServices.some(service => service.users && service.users.length > 0);
    
    if (hasServiceWithExpert) {
      const filteredServices = selectedServices.filter(service => service.users && service.users.length > 0);
      
      if (filteredServices.length !== selectedServices.length) {
        this.setState(prevState => ({
          scheduleData: {
            ...prevState.scheduleData,
            selectedServices: filteredServices
          }
        }), callback);
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
  

  handleStepChanged = (step) => {
    if (this.state.stepActive === 1 && step === 2 && !this.state.service?.length) {
      this.updateServicesClearTemporary(() => { // Pass a callback function to run after state update
  //      this.getSchedulerConfig(this.state.scheduleData.selectedServices, this.state.scheduleData.preferedTime.value, this.state.scheduleData.displayedDate);
      });
    }
    const services = this.state.scheduleData.selectedServices;
    const hasExpertServices = services && services.length > 0 &&
                              services.some(s => s.active && !s.disabled && s.users && s.users.length > 0);
    if (this.state.stepActive === 2 && step === 3 && !this.state.service?.length && hasExpertServices) {
      this.getExpertsBySlot();
    }
    if (step === 1 || step === 2) {
      this.setState(prevState => {
        const { client, ...rest } = prevState.scheduleData;
        return {
          scheduleData: rest
        };
      });
    }
    this.setState({ stepActive: step });
  };

  updateClientData = () => {
    const userData = LocalData.userData;
    const clientDataToUpdate = this.state.scheduleData.client;
    if (userData && clientDataToUpdate) {
      Object.keys(clientDataToUpdate).forEach(key => {
        const value = clientDataToUpdate[key];
        if (value === null) {
          if (key === 'secondary_email' || key === 'name') {
            userData[key] = '';
          } else if (key === 'invoice_primary' || key === 'invoice_secondary') {
            userData[key] = false;
          }
        } else {
          userData[key] = value;
        }
      });
    }
  }

  handleScheduleConfirmation = async () => {
    const {scheduleData} = this.state;
    try {
      const response = await ScheduleService.createSchedule(scheduleData);
      if (response?.success) {
        this.setState({ scheduleConfirmed: true});
        this.updateClientData();
      } else {
        if(!response?.code) {response.code='home';} 
        this.renderInfoModal(response?.title, response?.body, response?.code);
      }
    } catch (error) {
      console.error('handleScheduleConfirmation [catch error]', error);
    }
  };

  renderStepContent = () => {
    const { services, stepActive, scheduleData, schedulerConfig, considerFavoriteExpert, expertsBySlot } = this.state;
    switch (stepActive) {
      case 1:
        return (
          <Step1Schedule
            step={1}
            services={services}
            scheduleData={scheduleData}
            considerFavoriteExpert={considerFavoriteExpert}
            onDisableFavoriteExpert={() => this.setState({ considerFavoriteExpert: false })}
            onSelectedExpert={this.handleSelectedExpert}
            onSelectedServices={this.handleSelectedServices}
            onNewObservations={this.handleNewObservations}
          />
        );
      case 2:
        return (
          <Step2Schedule
            step={2}
            preferedTimeOptions={preferedTimeOptions}
            scheduleData={scheduleData}
            schedulerConfig={schedulerConfig}
            onSelectedExpert={this.handleSelectedExpert}
            onSelectedDate={this.handleSelectedDate}
            onPreferedTime={this.handlePreferedTime}
            onSelectedTime={this.handleSelectedTime}
            onChangeDisplayedDate={this.handleChangeDisplayedDate}
          />
        );

      default:
        return (
          <Step3Schedule
            step={3}
            scheduleData={scheduleData}
            expertsBySlot={expertsBySlot}
            location={schedulerConfig?.location}
            onSelectedExpert={this.handleSelectedExpert}
            onNewObservations={this.handleNewObservations}
            clientDataUpdated={this.clientDataUpdated}
          />
        );
    }
  };

  renderScheduleProccess = () => {
    if (this.state.scheduleConfirmed) {
      return <Fragment />;
    }
    
    const { services, stepActive, scheduleData } = this.state;
    return (
      <Fragment>
        <ScheduleTopInformation
          itemInfoSubtitle={scheduleData?.itemDetails?.subtitle}
          itemInfoTitle={scheduleData?.itemDetails?.title}
          itemInfoId={scheduleData?.itemDetails?.itemFoId}
          stepActive={stepActive}
          onStepActive={this.handleStepChanged}
        />
        {this.renderStepContent()}
        <ScheduleCTA
          stepActive={stepActive}
          servicesAvailable={services?.length > 0}
          scheduleData={scheduleData}
          onSave={this.isLiteVersion ? this.LoadLoginModal : this.handleScheduleConfirmation}
          onStepChanged={this.handleStepChanged}
        />
      </Fragment>
    );    
  };

  renderScheduleConfirmation = () => {
    if (!this.state.scheduleConfirmed) {
      return <Fragment />;
    }

    return (
      <ScheduleConfirmation />
    );
  };

  renderFilesSection = () => {
    if (this.state.scheduleConfirmed) {
      return <Fragment />;
    }
    const {actionCloseGallery} = this.state
    const { files } = this.state.scheduleData;
    const state = {
      "bars": 0,
      "name": "Serviço agendado"
    }
    const styleClass = 'mt-3 mt-md-4 schedule-page-files-container';
    return (
      <MediaFiles 
      key="media-files" // Ensure the key remains stable unless you need to force a remount
      data={{files, state}} styleClass={styleClass}
        onCreateAppointment={true}
        newMediaFileLoaded={this.newMediaFileLoaded}
        onDeleteMediaFile={this.deleteMediaFile}
        actionCloseGallery={actionCloseGallery}
        />
    );
  }

  closeGalleryModal = () => {
    // Update state to close the gallery modal
    this.setState({ actionCloseGallery: true });
    // Automatically reset the state after 3 seconds
    setTimeout(() => {
      this.setState({ actionCloseGallery: false });
    }, 1000); 
  }
  

  newMediaFileLoaded = (file, thumbnail) => {
    let formData = new FormData();
    formData.append('media', file, file.name);
    if (thumbnail) {
      formData.append('thumbnail', thumbnail, file.name);
    }
    this.renderModal(
      <Loader message='O seu ficheiro está a ser carregado...' inverted={false} local={true} big={false}></Loader>
    );
    const serviceId = "S-" + this.state.scheduleData.schedulerId;
    addMediaFile(this.cookies.get('sessionToken'), serviceId, formData).then(value => {
      this.refModal.current.closeModal();
      if (value) {
        if (value.code) {
          this.handleValueCodeResponse(value);
        } else {
          this.renderModal(
            <Information
              title={'Concluído!'}
              text={value.message}
              onClick={() => {
                this.refModal.current.closeModal();
                this.getAppointmentFiles();
              }} />
          );
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  deleteMediaFile = (id) => {
    const schedulerId = "S-" + this.state.scheduleData.schedulerId;
    this.renderModal(
      <Confirmation
        title='Atenção'
        text={Labels.confirmRemoveFile}
        confirmText='Remover'
        confirmationHandle={() => {
          this.renderModal(
            <Loader message='O seu ficheiro está a ser apagado...' inverted={false} local={true} big={false}></Loader>
          );
          removeMediaFile(this.cookies.get('sessionToken'), schedulerId, id).then(value => {
            if (value) {
              if (value.code) {
                this.renderModal(
                  <Information
                    title='Pedido Sem Sucesso'
                    text='Foi encontrado um problema durante o pedido, por favor tente mais tarde'
                    onClick={() => {
                      this.refModal.current.closeModal();
                    }} />
                );
              } else {
                this.renderModal(
                  <Information
                    title={'Concluído!'}
                    text={value.message}
                    onClick={() => {
                      this.refModal.current.closeModal();
                      this.closeGalleryModal();
                      this.getAppointmentFiles();
                    }} />
                );
              }
            } else {
              this.renderGenericError();
            }
          });
        }}
        declineHandle={() => this.refModal.current.closeModal()}
      />)
  }

  LoadLoginModal = () => {
    const stateToPersist = {
      data: this.state,             // The actual state data
      timestamp: new Date().getTime()  // The current time in milliseconds
    };
    // Save state to localStorage
    localStorage.removeItem('appState');
    localStorage.setItem('appState', JSON.stringify(stateToPersist));

    const redirectAuthFallback = window.location.href;
    const redirectAuthTemp = new URL(window.location.href.replace("-lite", ""));
    redirectAuthTemp.searchParams.set('submit', 'true');
    const redirectAuth = redirectAuthTemp.toString();
    this.cookies.set("redirectAuthFallback", redirectAuthFallback, Helper.cookiesLiteConfig);
    this.cookies.set("redirectAuth", redirectAuth, Helper.cookiesLiteConfig);

    window.location.href = Helper.getLiteRegisterUrl(true);
  }

  closeLoginModal = () => {
    this.setState({ showLoginModal: false });
  }

  renderInfoModal = (title, message, code) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => {
          switch (code) {
            case 409:
              this.handleStepChanged(2);
              this.refModal.current.closeModal();
              break;
            case 400:
                this.refModal.current.closeModal();
                this.props.history.push({
                  pathname: '/',
                  search: '?openAppointment=true'
                });
                break;  
            case 'home':
              this.refModal.current.closeModal();
              this.props.history.push({
                pathname: '/',
                search: '?openAppointment=true'
              });
              break;
            default:
              this.refModal.current.closeModal();
              break;
          }
        }}/>
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <React.Fragment>
        <div className="h-100 schedule-page-container container px-3 pb-5">
          {this.state.showLoginModal && <LoginModal onClose={this.closeLoginModal}/>}
          {this.renderScheduleProccess()}
          {this.renderFilesSection()}
          {this.renderScheduleConfirmation()}
          {/* {DebuggerComponent(this.state.scheduleData)} */}
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }
}

export default withRouter(SchedulePage);

export const DebuggerComponent = (data) => {
  return (
    <div
      className="mt-2 d-flex justify-content-start text-start w-100"
      style={{ fontSize: "13px" }}
    >
      <pre>Informação do agendamento: {JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}