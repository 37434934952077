import React, { Fragment } from "react";
import { GENERAL } from "../../assets/images/images";
import LocalData from "../../core/localData";
import Labels from "../../variables/labels";
// eslint-disable-next-line no-unused-vars
import LinkButton from "../button/LinkButton/LinkButton";

class CompanyCardList extends React.Component {
  constructor(props) {
    super(props);
    const { addActivated, searchTerm, companies } = this.props;
    this.state = {
      listData: companies,
      searchTerm: searchTerm,
      addActivated: addActivated,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchTerm !== this.props.searchTerm || prevProps.companies !== this.props.companies) {
      const listData = this.filterList(prevProps.addActivated, this.props.searchTerm);
      this.setState({ listData });
    }
  }

  filterList(addActivated, term) {
    if (!addActivated) {
      return LocalData.companies;
    }

    return term
      ? LocalData.newCompanies.filter((c) => c.name.toLowerCase().includes(term.toLowerCase()))
      : LocalData.newCompanies;
  }

  handleAddCompany = (/** @type {Company} */ c) => {
    window.open(c.url);
  };

  
  renderCard = (/** @type {Company} */ c, i) => {
    const { addActivated } = this.state;
    const renderImg = () => {
      if (!c || !c.logoUrl) {
        return (<div className="company-name-logo">{c?.name}</div>);
      }
      return <img src={c.logoUrl} alt="company_logo" />;
    };
    const renderNotiIcon = () => {
      const count = LocalData.companiesCount?.companies[c.domain];
      if (!count) {
        return;
      }
  
      return (
        <div className="tab-notification-container">
          <div className="tab-notification">
            <div className={`number ${count > 99 ? "small" : ""}`}> {count} </div>
          </div>
        </div>
      );
    };

    if (!addActivated) {
      return c.logged ? null : (
        <a
          key={i}
          className="company-card-container d-flex flex-column justify-content-center align-items-center"
          role={"button"}
          href={c.url}
          target={"_self"}
        >
          {renderNotiIcon()}
          <div className="card-company-logo-container flex-center"> {renderImg()} </div>
          <div className="card-company-name"> {c.name} </div>
        </a>
      );
    }

    return (
      <div
        key={i}
        className="company-card-container add-company d-flex flex-column justify-content-center align-items-center"
        role={"button"}
        onClick={() => this.handleAddCompany(c)}
      >
        <div className="card-company-logo-container flex-center"> {renderImg()} </div>
        <div className="card-company-name"> {c.name} </div>
        <LinkButton
          valueText={Labels.sidebarCompanies.addCompanyLinkText}
          icon={GENERAL.plusBlue}
        />
      </div>
    );
  };

  render() {
    const { listData } = this.state;
    return (
      <Fragment>
        {listData.map((c, i) => this.renderCard(c, i))};
      </Fragment>
    );
  }
}
export default CompanyCardList;
