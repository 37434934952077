import React from "react";
import Emitter from "../services";
import Helper from "../helper/helper.js";
import Cookies from 'universal-cookie';

class AndroidBack extends React.Component {
    static menuOpen = false;
    static scheduleMenuOldVisible = false;
    static modalOpenned = false;
    static history = null; 
    static cookies = new Cookies();

    static setHistory(history) {
        AndroidBack.history = history;
    }

    static setMenuOpen(status) {
        AndroidBack.menuOpen = status;
        if (!status) { AndroidBack.setScheduleMenuOld(status); } //Reset on menu close
    }

    static setScheduleMenuOld(status) {
        AndroidBack.scheduleMenuOldVisible = status;
    }

    static setModalOpenned(status) {
        AndroidBack.modalOpenned = status;
    }

    static handleAndroidBack() {

        if (AndroidBack.modalOpenned) {
            Emitter.emit("CLOSE_MODAL");
            return true;
        }

        if (AndroidBack.menuOpen) {
            if (AndroidBack.scheduleMenuOldVisible) {
                Emitter.emit("OLD_APPOINTMENTS_VISIBLE_BACK");
            } else {
                Emitter.emit("SIDEBAR_BACK");
            }
            return true;
        }
        if (Helper.isSchedulePage()) {
            Emitter.emit("SCHEDULE_BACK");
            return true;
        }

        if (!Helper.isHomepage() && !Helper.isHomepageLite()) {
            if (Helper.isServiceDetails() && AndroidBack.history) {
                AndroidBack.history.push("/");
            } else if (Helper.isServiceDetailsLite() && AndroidBack.history) {
                AndroidBack.history.push("/services-lite");
            } else {
                window.history.back();
            }
            return true;
        }

        if (Helper.isHomepage()) {
            try {
                window.Android.exit();
            } catch (error) {
                console.error(error);
            }
            return true;
        }    
        if (Helper.isHomepageLite() && AndroidBack.cookies.get('sessionToken')) {
            try {
                const details = {
                    title:'Voltar para a sua conta',
                    message: 'Deseja voltar para a sua conta?',
                    confirmText: 'A minha conta',
                    cancelText: 'Cancelar',
                    confirmationHandle: () => {
                      window.location.href = `${process.env.PUBLIC_URL}/services`;
                    },
                  };
                Emitter.emit("TOP_CONFIRMATION_MODAL",details);
            } catch (error) {
                console.error(error);
            }
            return true;
        }
        return true;
    }

}
export default AndroidBack;